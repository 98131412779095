$color-white: #ffffff;
$color-primary-fill-hovered: #ecf7f5;
$color-yellow: #ffd700;
$color-orange: #fa7700;
$color-orange-background: #fedec0;
$color-primary-old: #00bc95;
$color-primary-background: #cbfff4;
$color-red: #de3c46;
$color-warning: #ea4f50;
$color-warning-hovered: #d42833;
$color-warning-visited: #a60d17;
$color-light-blue: #3e6fa7;
$color-light-blue-background: #e0eaf6;
$color-dark-blue: #1f4066;
$color-darkest-blue: #052348;
$color-light-gray: #dadde0;
$color-text-gray: #6f8299;
$font-dark-blue: #0F2345;
$color-light-blue-gray: #ebeef2;
$color-gray: #ced5dc;
$color-bg-light-gray: #f2f2f2;
$color-bg-mid-gray: #e0e0e0;
$color-semantic-blue-background: #f1f4f8;
$color-bg-blue-gray: #e2e5ea;

// New Design colors
$color-primary: #A172FF;
$color-darker-primary: #854BFE;
$color-dark-primary: #6926F3;
$color-lighter-primary: #bca2fc;
$acu-background-white: #b5b9bf;
$acu-dark-blue: #dee5ed;
$acu-background-blue: #F0F2F6;
$acu-background-gray: #EDF2F6;
$acu-light-purple: #A172FF;
$acu-shadow-purple: #5844AD;
$acu-light-shadow: #CED5DF;
$acu-light-gray-text: #8791A2;
$acu-red-warning: #E03653;
$acu-green-success: #32B195;
$acu-positive-trend: #31B195;
$acu-green-background: #C0E6DE;

$acu-dark-purple: #170987;
$acu-yellow: #F49F09;
$acu-pink: #AF2387;
$acu-sky-blue: #05B2DC;
$acu-border-gray: #CDD8E4;
$acu-small-font-gray: #CDD8E4;
$acu-placeholder-gray: #9FA7B5;
$acu-coral-red: #FF3D48;

$acu-retro-primary: #142E5A;
$acu-retro-secondary: #ED51A3;
$acu-retro-gray: #C1CEDE;
$acu-retro-border-gray: #D7DFE2;
$acu-retro-gray-text: #565656;
$acu-retro-dark-gray-text: #596566;
$acu-retro-hover-gray: #F0F3F7;
