@import "../../components/colors.scss";

.right-side-controls {
	text-align: left;
	font-size: 1rem;
	display: flex;
	justify-content: flex;
	flex-flow: row;
	float: right;
	.control-width {
		min-width: 350px;
		padding-left: 0.25rem;
	}
	.control-width.small {
		min-width: 180px;
	}
}

.small-text {
	font-size: 14px;
}

.table {
	td {
		overflow: initial !important;
	}

	tr.clickable:hover {
		background: rgba(0, 0, 0, 0.05);
	}

	.options {
		.item {
			float: right;
			cursor: pointer;
		}
	}

	.disabled-user,
	.disabled-user:hover {
		opacity: 0.5;
	}
	.ui.table td > .ui.ribbon.label {
		left: calc(-1em - 1.2em);
	}
	.ui.table td > .ui[class*="right ribbon"].label {
		left: calc(100% + 1em + 1.2em);
	}
}

.form-input {
	width: 100%;
	width: -moz-available; /* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
	width: stretch;
	display: flex;
	flex-direction: column;
	padding: 1.25rem 0rem;
}

.minimum-three-quatres-width {
	min-width: 70%;
}

.form-button {
	.text {
		min-width: 60px !important;
	}
}

.insights-menu {
	.accordion {
		.accordion-header {
			display: flex;
			flex-direction: column;
		}
	}
}

.page,
.page.card {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;
}

#data-contributors {
	$small-icon-size: 20px;
	$tiny-icon-size: 13px;
	.top-nav {
		padding: 1rem 0px;
	}
	.ui.raised.card {
		border-radius: 0px !important;
		box-shadow: 0 0 0 0px $color-gray, 0 0px 0px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
		padding: 2rem;
		flex-flow: column;
	}
	.acu-size {
		width: 32px;
		height: auto;
	}
	.table {
		font-size: 15px;
		font-weight: 300;
		border: none;
		justify-content: initial;
		.icon,
		i.icon {
			font-size: $small-icon-size;
			vertical-align: baseline;
		}
		.icon.acumen-icon {
			width: $small-icon-size !important;
			height: $small-icon-size !important;
			display: flex;
			background-color: $color-bg-light-gray;
			border-radius: 30px;
			img {
				margin: auto;
				width: $small-icon-size - 6px;
				height: $small-icon-size - 6px;
			}
		}
		&.inner {
			border: initial;
			tr.thead {
				td {
					border-top: 1px solid rgba(34, 36, 38, 0.1);
					font-weight: 600 !important;
					background-color: $color-semantic-blue-background;
					height: 30px;
				}
			}
			tr {
				td {
					height: 45px;
					background-color: #eff1f4;
				}
			}
		}
	}
	th {
		background-color: $color-semantic-blue-background;
		border: none;
		.sort-icons {
			width: 2rem;
			margin-top: -0.25rem;
		}
	}
	th.clickable:hover {
		background-color: $color-light-gray;
		cursor: pointer;
	}
	tr {
		td {
			height: 59px;
			.acumen-icon,
			.active-only.icon {
				text-align: center;
				color: $color-bg-light-gray;
			}
			.active-only.icon.active {
				color: $color-darkest-blue;
			}
			.acumen-icon.active {
				background-color: $color-darkest-blue !important;
			}
			.icon.tiny {
				color: $color-darkest-blue;
				font-size: $tiny-icon-size;
			}
		}
		td:nth-child(1) {
			width: 120px;
		}
	}
	tr:hover {
		td {
			height: 59px;
			.acumen-icon,
			.icon {
				text-align: center;
				color: $color-light-gray;
			}
			.icon.active {
				color: $color-darkest-blue;
			}
			.acumen-icon.active {
				background-color: $color-darkest-blue !important;
			}
			.icon.chevron {
				color: $color-darkest-blue;
			}
		}
	}

	tfoot {
		td {
			background-color: white;
		}
	}
}

.content.light {
	font-weight: 200;
}

.expanded-search {
	$animation-duration: 0.5s;

	color: $color-darkest-blue;
	z-index: 0;
	input {
		border: none !important;
		background-color: transparent !important;
		border-radius: 0px !important;
		border-bottom: 2px solid $color-darkest-blue !important;
		padding-left: 0px;
		padding-bottom: 0.25em;
		font-size: 1rem;
	}
	.close-search {
		border: none !important;
		margin-left: -2em;
		background-color: transparent;
		outline: 0;
	}
	input.inactive {
		transition: all $animation-duration ease-in-out;
		width: 0px !important;
		padding-left: 0px;
	}
	background-color: transparent;
	input.active {
		transition: all $animation-duration ease-in-out;
		width: 150px;
		padding-left: 0px;
	}
	.icon.search {
		vertical-align: sub !important;
	}
	.ui.icon.input > input::placeholder {
		color: $color-light-blue;
	}
	.ui.icon.input > input:focus,
	.ui.icon.input > input {
		color: $color-darkest-blue;
	}
	.ui.icon.input > input.inactive {
		padding-right: 0px !important;
		width: 0px !important;
		padding-left: 0px;
	}
	.hoverable {
		visibility: hidden;
	}
}

.expanded-search:hover {
	.close-search {
		visibility: visible;
		cursor: pointer !important;
		color: transparent;
		border: none;
		.icon {
			color: $color-darkest-blue;
		}
	}
	.hoverable {
		visibility: visible;
	}
}

button:focus,
button:focus-visible,
button:focus-within {
	outline: 0;
}

.button.disabled-fade:disabled {
	color: $color-text-gray !important;
	&.primary {
		background-color: $color-light-gray !important;
	}
}

.spaced-header {
	display: flex;
	justify-content: space-between;
	.spaced-buttons {
		.button {
			margin-left: 1rem;
		}
	}
}

.pull-right {
	display: flex;
	flex-direction: row-reverse;
	.ui.button {
		margin: 1rem 0px;
	}
}

