.team-comparison {
	padding-top: 6px;
	padding-left: 21px;
	padding-right: 107px;

	.header-container {
		margin-top: 30px;
		margin-bottom: 16px;
	}
	.header {
		margin-bottom: 0;
	}

	.team-comparison-subheader {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
  		align-items: center;
		align-content: normal;

	}

	.team-comparison-note {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-top: 2px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}
	.team-comparison-title {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
		padding: 0;
		margin-bottom: 0;
		display: flex;
	}

	.team-comparison-last-generated {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-top: 5px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		height: 17px;
	}
	.team-comparison-metric-badge-filter-container {
		margin-top: 15px;
		margin-bottom: 15px;

		.team-comparison-sub-title {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			color: #000000;
		}
	}

	.team-comparison-sub-title {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 29px;
		color: #000000;
		margin-bottom: 10px;
	}

	.team-comparison-cards {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 16px 0 32px;

		> * {
			flex: 1;
		}

		> :not(:first-child) {
			margin-left: 8px;
		}
	}
}

