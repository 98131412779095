.tab {
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.002em;
	color: #9AAFB7;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #DDDDDD;
	padding: 10px 20px;
	transition: ease-in 100ms;
	cursor: pointer;

	&:hover {
		background-color: rgba(0, 0, 0, 0.025);
	}

	&:active {
		background-color: rgba(0, 0, 0, 0.05);
	}

	&.active {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.002em;
		color: #596566;
		border-bottom: 5px solid #925EFF;
	}
}
