.trend-total {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;

	&.variant-size-medium {
		.trend-total-value {
			font-size: 32px;
			line-height: 39px;
		}

		.trend-total-unit {
			line-height: 30px;
		}
	}

	&.variant-size-large {
		.trend-total-value {
			height: 48px;
			font-size: 40px;
			line-height: 30px;
		}

		.trend-total-unit {
			height: 48px;
			line-height: 17px;
		}
	}

	.trend-total-value {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 700;
		color: #000000;
	}

	.trend-total-unit {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-left: 6px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #979797;
	}
}
