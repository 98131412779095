.badge-breakdown-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;

	&:not(:first-child) {
		margin-top: 16px;
	}

	.badge-breakdown-item-label {
		min-width: 106px;
		max-width: 106px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		color: #0F2345;
	}

	.badge-breakdown-item-content {
		margin-left: 72px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #0F2345;
	}
}
