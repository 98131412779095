@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "./colors.scss";
@import "./vars.scss";

.set-page-z-index {
	z-index: $page-z-index;
}

.fixed-toaster-container {
	position: fixed;
	top: 0px;
	right: 0px;
	z-index: $highest-z-index;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	display: block;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
	width: 100%;
}

.header {
	color: $font-dark-blue;
	text-align: left;
	padding: 0;
	font-size: 22px;
	font-weight: 600;
	letter-spacing: -0.49px;
	line-height: 30px;
}

.tabs.ui.pointing.secondary.menu {
	min-height: 3.5rem;
	.item {
		height: 100%;
		border-color: #ced5dc;
		color: #62728c;
		font-weight: 500;

		&:hover:not(.active) {
			color: hsla(217, 18%, 31%, 1);
			border-color: #ced5dc;
			background: rgba(206, 213, 220, 0.59);
		}

		&.active {
			color: $color-primary;
			border-bottom: 5px solid $color-primary;
			margin-bottom: -3.5px;
			font-weight: 600;
			letter-spacing: -0.2px;
			line-height: 21px;
		}
	}
}

.ui.segment.tab {
	margin: 0;
}

#portal-root {
	.modal {
		position: relative;
		margin: 0 auto;
		height: auto;
	}
}

.modals {
	// font: "Inter Medium";
	.modal > .header {
		padding-bottom: 0rem;
		border-bottom: none;
	}

	.small {
		max-width: 400px;
		min-width: 400px;
	}

	display: flex;

	.actions.centered {
		text-align: center !important;
	}

	.warning {
		display: flex;

		.button {
			justify-content: center;
			size: 14px;
			line-height: 18px;
		}

		.ui.button {
			color: $color-primary;
			background-color: $color-white;
			border: 0.7px solid $color-primary;
		}

		.ui.button:hover {
			color: $color-primary;
			background-color: $color-primary-fill-hovered;
			border: 0.7px solid $color-primary;
		}

		.ui.button.primary {
			background-color: $color-warning;
			color: $color-white;
			border: 0.7px solid $color-warning;
		}

		.ui.button.primary:hover {
			background-color: $color-warning-hovered;
			color: $color-white;
			border: 0.7px solid $color-warning-hovered;
		}

		.ui.button.primary:visited {
			background-color: $color-warning-visited;
			color: $color-white;
			border: 0.7px solid $color-warning-visited;
		}
	}
}

tr:hover {
	.avatars-group {
		.image.avatar {
			border: 2px solid $color-bg-light-gray;
		}
	}
}

tr.active {
	.avatars-group {
		.image.avatar {
			border: 2px solid $color-bg-mid-gray;
		}
	}
}

$avatar-basic-border: 2px solid white;

.avatars-group {
	$avatar-margin: 18px;
	display: flex;
	flex-flow: row;
	margin-left: $avatar-margin;
	height: 100%;
	align-items: center;

	.avatar-icon-btn {
		display: inline-block;
		position: relative;
		margin-left: -$avatar-margin;
		cursor: default;

		.image.avatar {
			border: $avatar-basic-border;
			min-width: 32px !important;
			min-height: 32px !important;
			height: auto !important;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.extra-icon-btn {
		min-width: 35px;
		margin: 0px -10px;
		z-index: 1;
	}

	.extra-avatars {
		z-index: 10;
		margin: 10px 0px;
		position: absolute;
		padding: 5px;
		text-align: left;

		.image.avatar {
			border: $avatar-basic-border;
		}
	}

	.avatar-item {
		cursor: default;
		font-size: 0.8rem;
		white-space: nowrap;
		overflow: hidden;
	}
}

.avatar-selector {
	display: flex;
	flex-flow: row;

	.avatar-icon-btn {
		display: inline-block;
		position: relative;
		margin-left: -8px;

		.avatar-user-name {
			position: absolute;
			white-space: nowrap;
			width: 100%;
			justify-content: center;
			margin-top: 12px;
			display: none;
			color: $font-dark-blue;
			font-size: 14px;
			letter-spacing: -0.07px;
		}

		&:first-child {
			.avatar-user-name {
				justify-content: flex-start;
			}
		}

		.image.avatar {
			border: 2px solid $color-white;
			box-shadow: 0 0 0 2px transparent;
			width: 43px !important;
			height: 43px !important;
			min-width: 43px !important;
			min-height: 43px !important;
			background-repeat: no-repeat;
			background-size: cover;
			object-fit: cover;
		}

		.image.avatar:hover {
			border: 2px solid white;
			box-shadow: 0 0 0 2px $acu-light-purple;
		}

		&:hover {
			.avatar-user-name {
				display: flex;
			}
		}
	}

	.avatar-icon-btn.selected {
		z-index: 1;

		.image.avatar {
			border: 2px solid white;
			box-shadow: 0 0 0 2px $acu-light-purple;
		}
	}

	.avatar-icon-btn:hover,
	.avatar-icon-btn:focus,
	.avatar-icon-btn:focus-within {
		transform: translateY(-4px);
		z-index: 1;

		.label.static {
			display: block;
		}
	}

	.label.static {
		display: none;
		size: 6px;
		position: absolute;
		top: -40px;
		z-index: 1;
	}

	.label.static:hover {
		border: none;
	}

	.extra-icon-btn {
		min-width: 35px;
		margin-left: 8px;
		align-self: center;

		.dropdown-arrow {
			width: 1rem;
			height: 100%;
			margin: 0px;
			padding-left: 9px;
			display: flex;
			font-family: "outline-icons",serif;
		}
	}

	.extra-avatar-dropdown-button {
		position: relative;
	}

	.small-gray-btn.selected {
		.label {
			box-shadow: 0 0 0 2px white, 0 0 0 4px $color-primary;
		}
	}
}

.small-gray-label {
	color: $color-light-blue;
	min-width: 35px;

	.label {
		margin-top: 0.5rem;
		background-color: $color-light-blue-gray;
	}
}

.small-gray-btn {
	.label {
		color: $font-dark-blue;
		background-color: $color-light-blue-gray;
		margin-top: 0.5rem;
	}

	.label:hover,
	.label:focus-within,
	.label:focus {
		background: $color-gray;
		color: $color-darkest-blue;
		border: none;
		cursor: pointer;
		outline: none;
	}
}

.m-label {
	.label {
		color: $color-light-blue;
		background-color: $color-light-blue-gray;
		margin-top: 0.5rem;
	}
}

.blue-background {
	background-color: $color-semantic-blue-background !important;
}

.no-border {
	border: none !important;
}

.gray-font {
	color: $color-light-gray;
}

.gray-text-font {
	color: $color-text-gray;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.capitalize {
	text-transform: capitalize !important;
}

.link-button {
	border: none !important;
	color: $color-primary !important;
	background-color: transparent !important;
	padding-left: 1em !important;
}

.link-button:hover {
	color: $color-darker-primary !important;
}

.link-button:active {
	color: $color-dark-primary !important;
}

.white-bg {
	background-color: inherit !important;
}

.span-divider {
	position: relative;
	font-size: 12px;
	top: 0.25rem;
	border-right: 1px solid $color-bg-mid-gray;
	height: 1.75rem;
	margin: 0rem 0.65rem;
	font-size: 2em;
	vertical-align: text-top;
}

.light-blue-text {
	color: $color-light-blue !important;
}

.top-nav {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;

	.small-gray-label {
		margin: 0px 5px;
	}

	.page-error-message {
		color: $font-dark-blue;
		min-width: 350px;
		margin: auto;
		justify-content: center;
		padding: 0.3rem 2rem;
		background-color: $color-bg-mid-gray;
		display: flex;
		box-shadow: 0 0 0 0px $color-gray, 0 0px 0px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
	}
}

.transparent {
	color: transparent !important;
	background-color: transparent !important;
}

.dropdown-list {
	.dropdown-list-value-container {
		width: 100px;

		.counter {
			padding: 0px 1em;
			font-weight: 800;
		}
	}
}

.placeholder-1-rem {
	min-width: 1rem;
}

.left-align {
	text-align: left;
}

.check-icon-display {
	line-height: normal !important;
	font-size: 1rem !important;
}

.text-word-break {
	word-wrap: break-word;
}

.main-table {
	height: fit-content;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.right-padding .right-side-controls {
	padding-right: 0.9rem !important;
}

span.acu-capitalize::first-letter {
	display: inline-block;
	text-transform: capitalize !important;
}

.acu-capitalize {
	span::first-letter {
		display: inline-block;
		text-transform: capitalize !important;
	}
}

th.table-header.advanced-table-cell {
	user-select: none;
	&:hover {
		cursor: pointer;

		> .cell-content > .cell-label > .cell-filter-button {
			display: block;
		}
	}

    > .cell-content {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;

		> .cell-label {
			display: flex;
			flex-direction: column;
			white-space: break-spaces;

			&.align-center {
				align-items: center;
			}

			> .cell-filter-button {
				width: 24px;
				height: 24px;
				box-sizing: border-box;
				position: absolute;
				bottom: -12px;
				display: none;
				border: none;
				background-color: #FFFFFF;
				background-image: url("./svg-assets/filter-icon.svg");
				background-repeat: no-repeat;
				background-size: 16px 16px;
				background-position: center center;
				filter: drop-shadow(0px -1px 6px #CED5DF);
				border-radius: 50%;
				padding: 4px;

				&.visible {
					display: block;
				}

				&:hover {
					background-color: #F0F3F7;
				}

				&:active, &.visible {
					background-color: #C1CEDE;
				}
			}
		}

		> .cell-arrow {
			padding-left: 4px;
		}
    }
}

.work-done-by-type-card {
	height: 280px;
	background: transparent;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.no-wrap {
	white-space: nowrap;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}
