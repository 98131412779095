.developer-completed-work {
	flex: 1 1;
	flex-basis: 0;
	display: flex;
	flex-direction: column;
	margin-top: 16px;

	.developer-completed-work-container {
		flex: 1 1;
		flex-basis: 0;
		display: flex;
		flex-direction: column;

		.developer-completed-work-table-header {
			font-weight: 600;
			font-size: 14px;
		}
	
		.developer-completed-work-table-subheader {
			font-weight: 400;
			font-size: 14px;
		}
	
		.developer-completed-work-table-content {
			overflow: scroll;
			flex: 1 1;
			flex-basis: 0;
			display: flex;
			flex-direction: column;
		}
	
		.developer-completed-work-table-empty-state {
			text-align: center;
			align-items: center;
	
			.developer-completed-work-table-empty-state-img {
				margin-top: 123px;
			}
	
			.developer-completed-work-table-empty-state-text {
				text-align: center;
				align-items: center;
				font-weight: 600;
				font-size: 14px;
				width: 266px;
				height: 51px;
				margin-bottom: 123px;
				margin-top: 4px;
				display: inline-block;
			}
		}
	}
}
