@import "../../components/style.scss";

$primary-bg: #FFFFFF;
$primary-text: #0F2345;
$active-text-color: #A172FF;
$light-gray: #EEF2F6;

.main-sidebar.ui.menu.inverted {
	overflow: visible !important;
	font-weight: 500;
	border-bottom: 0 none !important;
	box-shadow: none;
	padding: 0 8px;
	.link,
	.link .active {
		color: $primary-text;
		padding: 13px 8px;
	}
	.title {
		padding: 0;
	}
	.icon.chevron {
		color: $primary-text;
		bottom: 2px;
		position: relative;
	}
	.title.active {
		color: $primary-text;
		font-weight: bold;
		.link {
			background-color: $primary-bg;
			color: $primary-text !important;
		}
	}
	.content {
		background-color: $primary-bg;
		.link.item {
			padding-left: 5em;
		}
	}
	.content.active {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		.item {
			border-bottom: 0 none !important;
			box-shadow: none;
		}
	}
	.accordion {
		max-height: calc(100% - 105px);
		overflow: auto;
		padding-bottom: 8px;
		min-height: 150px;
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		&::-webkit-scrollbar-track {
			background: rgba(0, 0, 0, 0.1);
		}

		&::-webkit-scrollbar-thumb {
		background: rgba(19, 19, 19, 0.5);
		}
	}

	i.icon {
		font-size: 11px;
		font-family: 'outline-icons';
	}
}

.acumen.logo {
	width: 190px;
	height: auto;
}

.menu-icon {
	width: 18px;
	height: 18px;
}

.ui.visible.left.sidebar ~ .pusher {
	max-width: calc(100vw - 275px);
}

.acumen-header {
	.breadcrumb a {
		color: $font-dark-blue;
		font-weight: normal !important;
		.icon {
			font-size: 11px;
			font-family: 'outline-icons', serif;
		}
	}
	.ui.breadcrumb a:hover {
		color: #285995;
	}
	.breadcrumb a:first-child .divider {
		margin-left: -5px;
	}
	.visible.menu {
		.item.selected {
			background: inherit;
		}
		.item.selected:hover {
			background: rgba(0, 0, 0, 0.05);
		}
	}
}


.show-user-dropdown-options{
	z-index: $menus-z-index;
}
