@import "../../../components/colors.scss";

.sprint-review-configuration-modal .ui.form {
	position: static;
}
.ui.grid.go-retro > * {
	padding-left: 3.33rem !important;
	padding-right: 3.33rem !important;
}

.go-retro-top-navbar {
	height: 6.25rem;
	align-items: flex-end;
	padding-bottom: 0.45rem;
	border-bottom: 1px solid $acu-retro-border-gray;

	.padded-icon {
		padding: 1rem 0 1rem 1rem;
	}

	.page-error-message {
		color: $font-dark-blue;
		max-width: 60rem;
		margin: auto 0 auto 2rem;
		justify-content: center;
		padding: 0.3rem 2rem;
		background-color: $acu-retro-gray;
		display: flex;
		box-shadow: 0 0 0 0 $color-gray, 0 0 0 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
	}
}

.go-retro-sprint-selector {
	padding: 0 0 0 2rem;

	.acu-select-input {
		width: 26.7rem;
		height: 3rem;
		background-color: $color-white;
		border: 1px solid $color-gray;
		box-sizing: border-box;
		border-radius: 0.3rem;

		.acu-select-input-selected-single,
		.acu-filters-input-value > span {
			font-size: 1.3rem;
			font-weight: 400;
			color: $acu-retro-gray-text;
		}

		.acu-filters-arrow {
			padding-right: 1rem;
		}
	}
}

.go-retro-avatar-selector {
	.ui.segment.users-dropdown {
		ul.items {
			padding: 0;
			margin: 0;

			li.item {
				padding: 0.766rem 0.933rem 0.766rem 1.066rem;
			}
		}
	}

	.avatar-icon-btn {
		margin-left: 0.27rem;

		.avatar-user-name {
			margin-top: -4.3rem;
		}

		&:first-child {
			.avatar-user-name {
				justify-content: center;
			}
		}
	}
}

.large-text {
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.9333rem;
	letter-spacing: -0.075px;
	white-space: initial;

	&.--bold {
		font-weight: 600;
		margin-top: 3.3rem;
		margin-bottom: 1.3rem;

		&:first-of-type {
			margin-top: 2rem;
		}
	}
}

.info-icon {
	height: 1.4rem;
	width: 1.4rem;
	align-self: flex-start;
	margin: 0 0 0 1.2rem;
	cursor: help;
	pointer-events: all;
}

.premium-icon {
	width: 7.5rem;
	align-self: center;
	margin: 0 0 0 1rem;
	pointer-events: all;
}

.card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;

	.card-text {
		font-size: 1.33rem;
		font-weight: 400;
		width: 75%;
		line-height: 1.6133rem;
		letter-spacing: -0.08px;
		color: $font-dark-blue;
	}
	.card-data {
		align-items: baseline;
		color: $acu-light-gray-text;
		font-weight: 600;
		font-size: 1.1rem;

		.card-value {
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.85rem;
			color: $acu-retro-dark-gray-text;
			padding-right: 0.65rem;
			letter-spacing: -0.3px;
			font-family: 'Rubik', sans-serif;
		}

		> span {
			white-space: nowrap;
		}
	}
}

.table-tabs {
	height: 100%;
	display: flex !important;
	border: none !important;

	> a {
		padding: 1.2rem 1.2rem 1.2rem 1.45rem !important;
		display: flex !important;
		flex: 1 0 !important;
		background-color: $color-white !important;

		&:first-child {
			border-radius: 0.2rem 0 0 0 !important;
		}

		&:last-child {
			border-radius: 0 0 0 0.2rem !important;
		}
	}
}

.go-retro-table {
	a.go-retro-tab.disabled:after {
		background-color: transparent !important;
		border: none !important;
	}

	a.go-retro-tab:not(.disabled) {
		&:hover:not(.active) {
			background-color: $acu-retro-hover-gray !important; /////////// ///// needs attention
			.colored-data {
				color: $acu-dark-purple;
			}
		}

		&.active {
			background-color: $acu-retro-gray !important;
		}

		&:after {
			font-size: 2rem;
			background-color: $acu-retro-gray !important;
		}
	}

	.go-retro-tab {
		min-height: 120px;
		max-height: 120px;
		box-sizing: border-box;

		.card-body {
			.card-text {
				font-size: 1.32rem;
				line-height: 1.6133rem;
				max-height: 4.07rem;
			}

			.card-data > div {
				font-size: 1.6rem;
				line-height: 1.8753rem;
			}

			.card-data > span {
				font-size: 1.0666rem;
				line-height: 1.2906rem;
			}
		}
	}
}

.tabs-and-table-grid {
	> div:first-child {
		flex: 1;
		padding-right: 0 !important;
		min-width: 14.87rem;
	}

	> div:not(:first-child) {
		flex: 5 0;
		padding-left: 0 !important;
	}

	&.tasks-by-category-grid > div:first-child {
		box-sizing: content-box;
	}
}

.table-container {
	padding: 0 !important;
	border: none !important;
	border-radius: 0 0.2rem 0.2rem 0 !important;
	margin-bottom: 0 !important;
}

.tasks-table-container {
	padding-bottom: 20px;
}

.tasks-table-footer-container {
	background-color: #FFFFFF;

	> .tasks-table-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 60px;
		padding: 18px;
		border-bottom: 1px solid #CED5DF;

		>.tasks-table-footer-notice {
			padding: 18px;
			text-align: right;
			color: #7D8CA2;
		}

		> .tasks-table-footer-cell {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			color: #0F2345;

			&.strong {
				font-family: default-regular-font, sans-serif;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0.002em;
			}
		}
	}
}

.tasks-table {
	border-collapse: separate;

	&.raised {
		border-spacing: 0 0.53rem !important;
		margin-top: -0.51rem !important;
	}

	> thead {
		height: 5.33rem;
		box-shadow: 0px 0.26rem 0.53rem rgba(0, 0, 0, 0.125) !important;

		> tr > th {
			background-color: $color-white !important;
			font-size: 1.066rem;
			line-height: 1.266rem;
			font-weight: 600 !important;
		}

		> tr > th.icon-cell {
			box-sizing: content-box;

			&.icon-cell-large {
				width: 2.933rem;
			}

			&.icon-cell-small {
				width: 2rem;
			}
		}
	}

	tr {
		&.raised-table-row {
			border-radius: 0.2rem;
			box-shadow: 0 -0.06rem 0.4rem $acu-light-shadow;
		}

		&:hover {
			background-color: initial !important;
		}
	}

	&.very.compact tr {
		> td, > th {
			padding: 0.8125rem 0.5333rem;
		}

		> td:first-of-type, > th:first-of-type {
			padding-left: 1.33rem;
		}

		> td:last-of-type, > th:last-of-type {
			padding-left: 1.33rem;
		}
	}

	td {
		cursor: default;
		font-size: 0.933rem;
		line-height: 1.133rem;
		font-weight: 400;
		letter-spacing: -0.08px;
		height: 68px;
		padding: 0 1rem;
		white-space: normal;

		.status-container {
			position: relative;

			.icon-label {
				font-size: 0.933rem;
				line-height: 1.133rem;
				font-weight: 600;
			}

			.status-change-arrow {
				font-size: 0.9333rem;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 10%;

				i {
					width: 14px;
					font-family: "outline-icons";
				}
			}
		}
	}

	tr:last-of-type {
		td {
			border-bottom: 1px solid rgba(34, 36, 38, 0.15);
		}
	}
}

.active.item::after {
	z-index: 5 !important;
}

.icons-container {
	display: flex;
	align-items: center;
	height: 100%;

	.task-icon:not(:first-child) {
		margin-left: 1.0666rem;
	}

	.task-icon.avatar-icon {
		height: 2rem !important;
		width: 2rem !important;
		margin-right: 0;
	}

	.task-icon.small-icon {
		height: 1.0666rem;
		width: 1.0666rem;
	}
}

.icon-cell:not(.icon-cell-first) {
	padding-left: 0.533rem !important;
}

.icon-cell:not(.icon-cell-last) {
	padding-right: 0.533rem !important;
}

.task-icon-container {
	display: flex;

	.task-icon.task-icon-large {
		height: 2rem !important;
		width: 2rem !important;
	}

	.task-icon.task-icon-small {
		height: 1.0666rem !important;
		width: 1.0666rem !important;
	}
}

.task-icon-label-container {
	display: flex;
	align-items: center;
}

.task-icon-label-container > :not(:first-child) {
	margin-left: 0.533rem;
}

.task-identifier {
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.1px;
	white-space: nowrap;

	.link {
		color: #275ab2 !important;
		border-bottom: 0.095rem solid;
	}
}

.task-title {
	font-weight: 400;
	font-size: 0.933rem;
	line-height: 1.133rem;
	white-space: initial;
	margin: 0 0.5rem 0 1rem;
	word-break: break-all;
}

.limit-table-height {
	height: 32rem;

	&.extended {
		height: 40rem;
	}
}

.height-container {
	&.burndown {
		height: 28.26rem;
	}

	&.category-tasks {
		height: 32rem;
	}

	&.all-tasks {
		height: 40rem;
	}

	&.planned-vs-actual {
		height: 36.67rem
	}
}

.empty-state {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.configurations-container {
	max-width: 42.33rem;
	margin: auto;
	padding-top: 5.33rem;

	.buttons-row {
		padding-top: 2rem;
		.button {
			margin: 0px;
		}
	}

	.config-field {
		padding: 0rem 0rem;
	}
	.acu-input {
		.ui.basic.label,
		.ui.input,
		input {
			background-color: $acu-dark-blue;
			border-color: $acu-dark-blue !important;
		}
	}
}

.table-shadow {
	box-shadow: 0px 0px 0.33rem $acu-light-shadow !important;
}

.extended-table {
	margin: 0 !important;

	> thead {
		border-radius: 0.2rem 0.2rem 0 0;
	}

	.large-text {
		font-size: 1.33rem;
		font-weight: 400;
	}
}

th.ten-percent-width {
	width: 10%;
}

.border-rounded {
	border-radius: 0.2rem !important;
}

.burndown-container {
	min-width: 0;

	> .large-text {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 30px;
		font-size: 1rem;
		line-height: 1.21rem;

		.info-icon {
			margin-top: 0;
			height: 1.4rem;
			width: 1.4rem;
		}
	}
}

.ui.card.tabs {
	> .card-body {
		padding: 1.4rem 1.4rem;

		.card-text {
			font-size: 1rem;
			line-height: 1.6133rem;
			font-weight: 500;
		}

		.card-data {
			font-size: 1.5rem;

			> .card-value {
				font-size: 2.4rem;
				line-height: 2.52rem;
				padding-right: 0.5rem;
				letter-spacing: -0.3px;
			}

			> span {
				font-size: 1rem;
				line-height: 1.5479rem;
				font-weight: 500;
				font-family: 'Rubik', sans-serif;
			}
		}
	}
}

.go-retro-configurations {
	h2.card-header {
		display: flex;
		justify-content: center;
		font-size: 32px;
	}

	.configuration-container {
		.config-field {
			margin-top: 20px !important;
		}
	}
	.form-control {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.configuration-name {
		display: flex;
		align-items: center;
		font-size: 1.07rem;
	}
	.ui.input {
		background-color: transparent !important;

		&.acu-labeled-input {
			justify-content: end;
			align-items: center;
			margin-right: 15.93rem;
		}

		input {
			width: 4.73rem !important;
			background-color: $color-white !important;
			border-radius: 0.27rem !important;
			color: $acu-retro-dark-gray-text !important;
			border: 0.07rem solid $color-gray;
		}
		.label {
			background-color: transparent !important;
			border: transparent;
			width: 6rem;
			color: $acu-retro-dark-gray-text !important;

		}
	}
	.acu-select .acu-select-input .acu-select-input-selected-single{
		color: $acu-retro-dark-gray-text !important;
	}

	.input-field {
		min-width: 26.67rem !important;

		.acu-select {
			position: static;

			.acu-select-input {
				background-color: $color-white !important;
				height: 2.5rem;
				border-radius: 0.27rem;
				color: $acu-retro-dark-gray-text !important;
			}
		}

		.acu-select-input-container {
			.acu-select-input-items {
				max-height: 250px;
			}
		}
	}
}

.planned-vs-actual-padding {
	padding: 25px 0;
}

.sprint-summary-tabs {
	display: flex;
	flex-direction: column;
	margin-right: 0.67rem;

	> .ui.card.header {
		flex: 1;
		opacity: 1;
		width: 100%;
		margin-bottom: 0;
	}

	.sprint-dates {
		display: flex;
		flex-direction: row;
		margin: 0;

		.card-padding {
			padding: 1.8rem 1.4rem;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: space-between;

			.card-body {
				width: max-content;

				.card-text {
					font-size: 1rem;
					font-weight: 500;
					line-height: 1.27rem;
					letter-spacing: -0.18px;
					width: 100%;
				}

				.card-value {
					font-size: 1.7rem;
				}

				.colored-data {
					padding-right: 0;
				}
			}
		}
	}


	> div:not(:first-of-type) {
		margin-top: 0.67rem;
	}
}

.active-card {
	.card-text {
		font-weight: 400;
	}

	.colored-data:not(.disabled-value) {
		color: $acu-dark-purple;
	}
}

.disabled-value {
	color: $acu-retro-hover-gray !important;
}

.task-category.bullet {
	width: 0.8rem;
	height: 0.8rem;
	margin-right: 0.4rem;

	&.red {
		background: -webkit-gradient(linear, center top, center bottom, from(#efa5ae), to(#d81e35));
		-webkit-background-clip: text !important;
		background-clip: inherit;
		-webkit-text-fill-color: transparent;
	}

	&.blue {
		background: -webkit-gradient(linear, center top, center bottom, from(#8b84c3), to($acu-dark-purple));
		-webkit-background-clip: text !important;
		background-clip: inherit;
		-webkit-text-fill-color: transparent;
	}

	&.purple {
		color: #8e81f7;
	}
}

.ui.popup.sprint-date-time-tooltip {
	min-width: 14.87rem;

	.sprint-date-time-tooltip-content {
		.sprint-date-time:not(:first-child) {
			margin-top: 1.33rem;
		}
	}
}

.charts-row {
	display: flex;
	flex-direction: row;
	margin-top: 24px;

	> :not(:first-child) {
		margin-left: 10px;
	}

	> .size-1 {
		flex: 1;
		min-width: 417px;
	}

	> .size-2 {
		flex: 2;
	}

	.highcharts-container {
		height: 214px !important;
	}

	.highcharts-legend {
		.highcharts-pie-series.highcharts-legend-item {
			> span {
				line-height: 23px !important;
				letter-spacing: -0.203077px !important;
			}
		}

		&.bugs-added-mid-sprint-chart-legend {
			.highcharts-pie-series.highcharts-legend-item {
				> span {
					> b {
						padding: 0 13px;
					}
				}
			}
		}

		&.issues-done-by-type-chart-legend {
			.highcharts-pie-series.highcharts-legend-item {
				width: 184px;

				> span {
					display: inline-flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 184px;

					> b {
						padding-left: 13px;

						> span {
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}

.summary-pie-chart-count {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 22px !important;
	line-height: 24px;
	text-align: center;
	color: #142E5A;
}

.summary-pie-chart-count-label {
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.25px;
	color: #142E5A;
}

.tab-category-cards {
	display: flex;
	flex-direction: row;
	padding-bottom: 17px;

	> :not(:first-child) {
		margin-left: 10px;
	}
}

.icon-column-header {
	width: 47px;
	max-width: 47px;
}

.title-column-header {
	min-width: 355px;
}

.task-state-in-sprint-column-header {
	width: 117px;

	@media (min-width: 1700px) {
		width: 185px;
	}
}

.estimation-column-header {
	width: 62px;
}

.actual-cycle-time-column-header {
	width: 114px;

	@media (min-width: 1792px) {
		width: 154px;
	}
}

.state-progress-bar-column-header {
	width: 192px;

	@media (min-width: 1852px) {
		width: 200px;
	}

	> .cell-content {
		padding: 0 16px;
	}
}

.start-status-column-header {
	width: 159px;

	@media (min-width: 1852px) {
		width: 192px;
	}
}

.end-status-column-header {
	width: 159px;

	@media (min-width: 2178px) {
		width: 214px;
	}
}
