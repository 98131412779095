.team-comparison-quadrants {
	display: flex;
	justify-content: center;
	flex-direction: row-reverse;

	margin-bottom: 20px;

	.quadrants-metric-selection-container {
		display: flex;
		flex-direction: column;
		background: white;

		padding: 25px;
		padding-left: 50px;
		padding-right: 40px;
		width: 25%;

		.quadrants-metric-selection-header {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			color: #000000;

			font-weight: 400;
			font-size: 18px;
			line-height: 22px;
		}

		.checkbox-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			padding: 35px 0px;
		}
	}

	.quadrants-chart-container {
		width: 75%;
	}

}