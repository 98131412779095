@import "./../../../components/colors.scss";
.team-dashboard-tab {
	.full-width {
		width: 100% !important;
	}
	.acu-scroll {
		max-height: 330px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.need-attention-section{
		padding-top: 15px;
	}

	.ui.tab {
		padding: 0;
	}

	.ui.table.card,
	.burndown-section > .card {
		padding: 20px 15px 15px 15px;
	}

	.ui.segment.tab:last-child {
		margin-bottom: 0;
	}

	.new-bugs-section{
		.team-dashboard-table {
			height: 415px;

			.acu-scroll {
				max-height: 415px;
			}

			.card {
				box-shadow: none;
			}
		}
	}


	.burndown-container >  .highcharts-container{
		padding-top: 10px;
	}

	.ui.card > .table,
	.table,
	.ui.tab {
		border: none;
		box-shadow: none;
	}

	.ui.card {
		border: none;
		border-radius: 0px !important;
		box-shadow: 0 0 0 0px $color-gray, 0 0px 0px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
		flex-flow: column;
	}

	.pr-highlight-text,
	.task-highlight-text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.pr-title, .task-title {
		margin-left: 4px;
	}
	.pr-title:after,
	.task-title:after {
		content: "\00a0\00a0\00a0\00a0\00a0\00a0 ";
	}

	.pr-highlight-container,
	.task-highlight-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
	}

	.ui.header,
	.ui.menu .item {
		color: $color-darkest-blue
	}

	.db-section {
		margin-bottom: 24px;
		display: flex;
		.db-section-item {
			flex: 1;
			flex-shrink: 0;
			min-width: 50%;
			&:first-child {
				padding-right: 12px;
			}
			&:last-child {
				padding-left: 12px;
			}
		}
		@media(max-width: 700px) {
			flex-direction: column;
			.db-section-item {
				margin-bottom: 24px;
				&:first-child {
					padding-right: 0;
				}
				&:last-child {
					padding-left: 0;
				}
			}
		}
	}
}
