.badge-breakdown {
	flex: 1 1;
	flex-basis: 0;
    display: flex;
    flex-direction: column;
	.badge-breakdown-list {
		flex: 1 1;
		flex-basis: 0;
		display: flex;
		flex-direction: column;
		overflow: auto;
		padding-top: 29px;
		padding-bottom: 13px;

		.badge-breakdown-list-item-empty-state {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #8791A2;
		}
	}
}
