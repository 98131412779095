@import "../colors.scss";
@import "../vars.scss";

@mixin chip-multiselect-font {
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 1.066rem;
	line-height: 1.255rem;
}

.chip-multiselect {
	.chip-multiselect-static-value-label {
		padding-right: 6px;
	}

	.chip-multiselect-dropdown-button {
		color: $acu-retro-secondary;
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		margin-left: 0.533rem;

		> .chip-multiselect-dropdown-button-icon {
			font-family: 'DM Sans', sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 1.333rem;
			line-height: 1.733rem;
			letter-spacing: -0.006rem;
		}

		> .chip-multiselect-dropdown-button-label {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 0.933rem;
			line-height: 1.133rem;
			text-decoration-line: underline;
			margin-left: 0.4rem;
		}
	}
}

div.chip-multiselect-dropdown {
	$border-color: #CED5DC;
	width: 21.333rem;
	top: 0;
	z-index: 999999;

	.chip-multiselect-option-wrapper {
		padding: 0.7rem 1.066rem;

		> .chip-multiselect-option {
			@include chip-multiselect-font;

			display: flex;
			flex-wrap: nowrap;
			padding: 0;
			color: $acu-retro-primary;

			> .chip-multiselect-option-icon {
				margin: 0;
				padding: 0;
			}

			> .chip-multiselect-option-label {
				padding-left: 1.066rem;
			}
		}
	}

	.chip-multiselect-search-field {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 0.066rem solid $border-color;

		.search-icon {
			width: 1.333rem;
			height: 1.333rem;
			margin-left: 1.133rem;
			background: url("../svg-assets/search-icon.svg") center center no-repeat;
		}

		input.chip-multiselect-search-input {
			@include chip-multiselect-font;

			width: 100%;
			height: 100%;
			border: none;
			padding: 1.066rem;

			&:first-child {
				padding-left: 1.133rem;
			}

			&::placeholder {
				color: #9AAFB7;
			}

			&::-webkit-search-cancel-button {
				cursor: pointer;
			}
		}
	}
}
