.dev-stats-metric-modal {
	max-width: 700px;

	.modal-header-title {
		font-family: default-bold-font, sans-serif;
	}

	.category-title {
		margin-bottom: 10px;
		font-weight: bold;
		color: #854BFE;
	}

	.metric-checkbox {
		padding: 10px;
	}

	.dev-stats-metric-modal-header-disclaimer {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.002em;
		color: #0F2345;
		margin-left: 8px;
	}
}
