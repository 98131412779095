.deployment-frequency-empty-state {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

}

.deployment-frequency-empty-state-content {
	min-width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 78px;
	.deployment-frequency-empty-state-content-header {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #001A72;
	}

	.deployment-frequency-empty-state-content-text {
		margin-top: 13px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 144.52%;
		text-align: center;
		color: #001A72;
	}

	.deployment-frequency-empty-state-content-action {
		width: 231px;
		margin-top: 23px;
		padding: 14px 0;
		background: #925EFF;
		border-radius: 5px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: #FFFFFF;
		border: none;
		cursor: pointer;
	}

	.deployment-frequency-empty-state-content-action:hover {
		background-color: #8354e5;
	}
}
