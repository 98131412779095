@import "../../../components/colors.scss";

.edit-team-form {
	$mandatoryColor: rgba(#ea4f50, 0.5);
	.team-edit {
		margin-bottom: 1rem;
	}

	label {
		font-weight: 600;
	}

	.create-team-button {
		padding-top: 3rem;
	}

	input.required {
		border-color: $mandatoryColor !important;
	}

	.message.required {
		color: $color-text-gray;
		font-size: 0.75rem;
	}

	.required {
		div[class*="css-"] {
			border-color: $mandatoryColor !important;
		}
	}
	.primary.button:disabled {
		opacity: 1 !important;
		cursor: not-allowed;
	}
	.pull-right {
		.button {
			margin: 1rem;
		}
	}
}

.form-padding {
	padding: 2rem !important;
	padding-bottom: 0rem !important;
}

.clickable {
	div[class*="-control"]:hover {
		cursor: pointer;
	}
}
