.developer-stats-team-trends-card-header {
	padding: 20px 8.5px 13px 20px;
	border-bottom: 1px solid #DDDDDD;

	.developer-stats-team-trends-card-header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&:not(:first-child) {
			margin-top: 3px;
		}
	}


	.developer-stats-team-trends-card-header-title {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}

	.developer-stats-team-trends-card-header-team-select {
		.developer-stats-team-trends-card-header-team-select-trigger {
			width: 182px;

			.developer-stats-team-trends-card-header-team-select-trigger-label {
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				color: #925EFF;
			}

			.developer-stats-team-trends-card-header-team-select-trigger-arrow {
				background-color: #5E6C84;
			}
		}
	}

	.developer-stats-team-trends-card-header-team-count {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;
		color: #000000;
	}
}
