.metric-card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 228px;
	height: 145px;
	padding: 16px 16px 16px 12px;
	background: #FFFFFF;
	border-radius: 4px;

	&.variant-elevated {
		box-shadow: 0px -1px 6px #CED5DF;
	}

	.metric-card-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		min-width: 80px;
		height: 100%;

		.metric-card-info-title-container {
			.metric-card-info-title {
				max-height: 45px;
				overflow: hidden;
				text-overflow: ellipsis;
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				color: #000000;
			}

			.metric-card-info-subtitle {
				margin-top: 2px;
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: #979797;
			}
		}

		.metric-card-info-sum {
			.metric-card-info-sum-value {
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 32px;
				line-height: 30px;
				color: #0F2345;
			}

			.metric-card-info-sum-unit {
				margin-top: 2px;
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-size: 14px;
				line-height: 10px;
				color: rgb(126, 126, 126);
				font-weight: lighter;
			}
		}
	}

	.metric-card-stats {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		height: 100%;
	}
}
