.custom-table-container {
	max-width: 100%;
	overflow: auto;

	.custom-table {
		width: 100%;
		border: none;
		border-radius: 4px;

		.custom-table-head.custom-table-head-main {
			border-radius: 4px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);

			&.variant-sticky {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				z-index: 150;
			}
		}

		.custom-table-body.custom-table-body-main {
			&:first-of-type {
				.custom-table-row:first-child {
					.custom-table-cell {
						padding-top: 5px;
					}
				}
			}

			.custom-table-row {
				&:first-child {
					.custom-table-cell {
						&:first-child {
							.custom-table-cell-content {
								border-top-left-radius: 4px;
							}
						}

						&:last-child {
							.custom-table-cell-content {
								border-top-right-radius: 4px;
							}
						}
					}
				}

				&:last-child {
					.custom-table-cell {
						&:first-child {
							.custom-table-cell-content {
								border-bottom-left-radius: 4px;
							}
						}

						&:last-child {
							.custom-table-cell-content {
								border-bottom-right-radius: 4px;
							}
						}
					}
				}
			}
		}
	}
}
