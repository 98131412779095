@import "../../../components/colors.scss";
@import "../../../components/style.scss";

.pr-breakdown-container {
	height: 100%;
	padding-top: 6px;
	padding-left: 21px;
	padding-right: 107px;
	color: $font-dark-blue;

	.pr-breakdown-title {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
		padding: 0;
		margin-bottom: 0;
		display: flex;

		.export-button {
			margin-left: 10px;
		}
	}
	.header-container {
		margin-top: 30px;
		margin-bottom: 16px;
	}

	.flex-gap {
		display: inline-flex;
		flex-wrap: wrap;
	}

	.flex-gap>div {
		margin: 6px;
	}

	.pr-table-container {
		margin-top: 14px;
	}
	.filter-controls {
		z-index: 10001;
	}

	.pr-filter-container {
		margin-top: 15px;

		.pr-filter-title {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			color: #000000;
		}
	}
}
