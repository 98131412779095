.metric-tester-page {
	.header {
		font-weight: 700;
		font-size: 2em;
		color: #0F2345;
		text-align: left;
		padding: 0;
	}

	.react-datepicker-wrapper, .react-datepicker__input-container { display: block; }
	.react-datepicker-wrapper .react-datepicker__input-container input {
		height: 38px;
		width: 100%;
	}
}
