@import "../../components/colors.scss";
@import "../../components/style.scss";

$screen-breakpoint: 1800px;
$min-card-width: 125px;

.teams-report-container {
	padding-top: 2rem;
	color: $font-dark-blue;

	.cards-row {
		min-height: 220px;
	}

	.sticky-cards-row {
		position: sticky !important;
		top: 0;
		z-index: 1;
		background: #f7f7f7;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.stack-bar-centered {
		padding: 1.5rem 0px 0px 1.5rem !important;
	}

	.ui.segment.loading {
		margin: auto;
	}
}

.cycle-header {
	font-size: 16px;
	margin-right: 20px;
	padding: 0.5rem;
	padding-bottom: 0.25rem;

	.big-text {
		font-weight: 800;
	}

	.cycle-count {
		margin: 0px;

		span.small-text {
			margin-top: 0.52em;
		}

		span:not(:last-child) {
			padding-right: 0.5rem;
		}

		display: block;
	}
}

.acu-card {
	border-radius: 1px;
	background-color: white;
	border: transparent;
	box-shadow: 0 0 0 0px $acu-light-shadow, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.underlined-row {
	height: 4rem;
	margin: auto !important;
}

.hide-overflow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.underlined-row:not(:last-child) {
	border-bottom: 1px solid #ced5df;
}

.column.only-side-padding {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.no-right-padding {
	padding-right: 0px !important;
}

.no-left-padding {
	padding-left: 0px !important;
}

.left-padding {
	padding-left: 0.25em;
}

.tiny-text {
	font-size: 12px;
}

.small-text {
	font-size: 14px;
}

.medium-text {
	font-size: 16px;
}

.big-text {
	font-size: 24px;

	@media only screen and (max-width: $screen-breakpoint) {
		font-size: 18px;
	}
}

.huge-text {
	font-size: 42px;

	@media only screen and (max-width: $screen-breakpoint) {
		font-size: 30px;
	}
}

.flex-spaced {
	display: flex;
	justify-content: space-between;
}

.velocity-cards {
	.trend-card {
		width: 22%;
	}
}

.dev-stat-cards,
.output-cards,
.quality-cards {
	.trend-card {
		width: 100%;
	}

	.trend-card:last-child {
		margin-right: 0px;
	}
}

.trend-card {
	min-width: $min-card-width;
	margin-right: 20px;

	.trend-card-header {
		padding: 0.75rem;
		max-height: 115px;
		height: 115px;

		.value {
			position: absolute;
			top: 65px;

			.huge-text,
			.big-text {
				padding-right: 0.5rem;
			}
		}

		.change {
			display: block;
		}

		.secondary-title {
			color: $acu-light-gray-text;
		}
	}

	.trend-chart {
		position: relative;
		z-index: 0;
	}
}

.dev-stats-indicator {
	display: flex;
	justify-content: space-around;

	.value {
		padding-right: 0.5rem;
	}
}

.dev-stats-table-column span,
.dev-stats-indicator>* {
	vertical-align: sub;
}

.align-center {
	align-items: center !important;
	height: 100%;
}

.left-trend-cards {
	flex-direction: row-reverse !important;

	.trend-card:first-child {
		margin-right: 0px;
	}
}

.velocity-charts-header {
	margin-top: 2rem;

	.charts-card-header {
		height: 77px;

		.headers-tabs {
			padding-top: 0rem !important;
			padding-bottom: 0px !important;
		}

		.header-title-only-tab {
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-width: 125px;
			width: 22%;

			p {
				display: flex;
				justify-content: center;
				font-weight: 600;
				white-space: nowrap;
			}
		}

		.trend-card {
			height: 100%;

			@media only screen and (max-width: $screen-breakpoint) {
				padding: 0rem 0.5rem;
			}

			.column-title {
				font-size: 16px;
				padding-top: 0.75rem;
				min-width: $min-card-width;

				@media only screen and (max-width: $screen-breakpoint) {
					font-size: 14px;
				}
			}

			.value {
				margin-top: 0.25rem;
			}
		}
	}

	.title-with-menu {
		h2 {
			margin-bottom: 0rem;
		}

		.control.control-width {
			width: 308px;
			margin-bottom: 0.5rem;
		}
	}
}

.vertical-centered {
	display: flex;
	justify-content: center;
	height: 100%;
	flex-direction: column;
}

.legend-container {
	@extend .vertical-centered;
}

.pie-chart-container {
	@extend .vertical-centered;
}

.output-charts-header {
	margin-top: 2rem;

	.charts-card-header {
		height: 77px;

		.total {
			margin: auto;
			margin-top: 25%;
		}

		.headers-tabs {
			padding-top: 0rem !important;
			padding-bottom: 0px !important;
		}

		.trend-card {
			height: 100%;
			padding: 0rem 1rem;

			@media only screen and (max-width: $screen-breakpoint) {
				padding: 0rem 0.5rem;
			}

			.column-title {
				font-size: 16px;
				padding-top: 0.75rem;
				min-width: $min-card-width;

				@media only screen and (max-width: $screen-breakpoint) {
					font-size: 14px;
				}
			}

			.value {
				margin-top: 0.25rem;
			}
		}
	}
}

.charts-line-container {
	height: 100%;
	padding: 3rem;

	.title {
		padding-bottom: 0.5rem;
	}

	.highcharts-title {
		text-align: center;
	}
}

.margin-auto {
	margin: auto;
}

.summary-chart-block {
	margin: auto;
	padding-left: 25%;
	padding-top: 25%;

	.value {
		font-size: 28px;
		padding: 0.5rem 0px;
		font-weight: 600;
	}

	.sub-title {
		font-size: 16px;
		padding: 0.5rem 0px;
	}

	.time-span-title {
		color: $acu-light-gray-text;
		font-size: 14px;
	}

	.right-border {
		padding-right: 2rem;
		border-right: 1px solid #dadde0;
	}
}

.icon.chart-tooltip {
	font-size: 1rem;
	vertical-align: super;
	color: $acu-light-gray-text;
}

.highcharts-background {
	height: 110%;
}

.highcharts-container {
	overflow: visible !important;
}

.acu-legend {
	.acu-legend-item {
		font-size: 12px;
		display: flex;
		flex-direction: row;
		padding: 0.5em;

		.legend-item-value {
			font-weight: 800;
			padding-left: 2em;
		}

		.circle {
			border-radius: 50%;
			width: 0.8em;
			min-width: 0.8em;
			height: 0.75em;
			margin: auto 1em auto 0em;
		}

		p {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
	}

	.acu-legend-item.hovered {
		background-color: $acu-background-blue;
		border-radius: 5px;
	}
}

.dev-stats-table {
	.avatar-container {
		padding: 1rem;
	}
}

.dev-stat-cards {
	padding: 0rem 0rem;
}

.avatar-container {
	margin: 0px 10px;
}

.dev-stats-table-column {
	width: calc((100% - 120px)/7);
	padding: 0px !important;

	div,
	p {
		padding: 1rem;
	}

	.change {
		font-weight: 600;
	}

	.label {
		max-height: 1.5rem;
	}
}

.dev-stats-table-column:not(:last-child) {
	margin-right: 20px;
}

.analytics-teams-selector {
	z-index: $selectors-z-index;

	div[class*="-menu"] {
		z-index: $selectors-z-index;
	}
}
