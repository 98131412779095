.action-buttons {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	&.info-box-shown {
		justify-content: space-between;
	}
}

.info-box {
	display: flex;
	align-items: center;
}

.icon-info {
	position: relative;
	margin-right: 5px;
	&:before {
		font-size: 28px;
	}
}

.blink {
	animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0.3;
	}
}
