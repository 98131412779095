.custom-select-trigger {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	max-width: 100%;
	background-color: transparent;
	border: none;
	border-radius: 4px;
	padding: 4px 4px 4px 8px;
	transition: 100ms ease-in-out;

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.custom-select-trigger-label {
		max-width: 95%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #0F2345;
	}

	.custom-select-trigger-arrow {
		width: 9px;
		height: 5px;
		margin-left: 1px;
		clip-path: polygon(0 0, 50% 100%, 100% 0);
		background-color: #000000;
	}
}
