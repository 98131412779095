.custom-select-dropdown {
	position: absolute;
	min-width: 138px;
	z-index: 9999;
	margin: 4px 0 0;
	padding: 0;
	list-style-type: none;
	background: #FFFFFF;
	box-shadow: 0px -1px 6px #CED5DF;
	border-radius: 4px;
	.group-title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
  		padding: 11px 20px 8px 10px;
		background-color: #FFFFFF;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.002em;
		color: #0F2345;
		text-overflow: ellipsis;
		cursor: default;
		transition: 100ms ease-in-out;
  		border-bottom: 1px solid #E5E9F2;
	}
}
