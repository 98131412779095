.app-tooltip-trigger-container {
	position: relative;
}

.app-tooltip {
	position: absolute;
	background: #FFFFFF;
	color: #0F2345;
	box-shadow: 0px -1px 6px #CED5DF;
	border-radius: 4px;
	padding: 10px;
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	pointer-events: none;

	&.variant-bold {
		font-family: default-bold-font, sans-serif;
		font-weight: 600;
	}
}

