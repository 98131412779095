@import "../../../components/colors.scss";
@import "../../../components/style.scss";

.jira-statuses {
	.table-header {
	user-select: none;

	&:hover {
		cursor: pointer;
	}
	}

	.form-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 23px;

	.form-title {
		font-size: 28px;
		color: $font-dark-blue;
		margin-bottom: 0px;
	}
	}
	i {
	padding-left: 30%;
	&.check {
		color: $acu-green-success;
	}
	&.times {
		color: $acu-red-warning;
	}
	}

	.status-group-label {
		text-transform: capitalize;
	}
}

.icon-label {
	@extend .flex-row;
	@extend .no-wrap;
	@extend .ellipsis;
	img {
		padding-right: 0.25em;
	}
	text-transform: capitalize;
	height: 100%;
}
