.developer-stats-team-trend {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 9px 0;
	cursor: default;

	.developer-stats-team-trend-info {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		min-width: 100px;

		.developer-stats-team-trend-info-badge {
			width: 13px;
			min-width: 13px;
			height: 13px;
		}

		.developer-stats-team-trend-info-badge-name {
			margin-left: 8px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 11px;
			line-height: 110.02%;
			color: #212121;
		}
	}

	.developer-stats-team-trend-stats-lines {
		flex: 1;
		margin: 0 10px;

		.developer-stats-team-trend-stats-current-line {
			height: 8px;
			border-radius: 3px;
		}

		.developer-stats-team-trend-stats-previous-line {
			height: 5px;
			margin-top: 2px;
			border-radius: 3px;
			background-color: #DDDDDD;
		}
	}
	.developer-stats-team-trend-stats {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.developer-stats-team-trend-stats-current-value {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 15px;
			line-height: 18px;
			color: #000000;
		}

		.developer-stats-team-trend-stats-change-rate-icon {
			margin-left: 4.5px;
			&.icon-placeholder {
				height: 16px;
				width: 16px;
			}
		}
	}
}

.developer-stats-team-trend-stats-change-rate {
	font-family: default-bold-font, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	text-align: center;

	&.variant-growth {
		color: #61C1AA;
	}

	&.variant-decline {
		color: #E24B63;
	}
}
