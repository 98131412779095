.workforce-health {
	padding-top: 6px;
	padding-left: 21px;
	padding-right: 107px;

	.header-container {
		margin-top: 30px;
		margin-bottom: 16px;
	}
	.header {
		margin-bottom: 0;
	}

	.workforce-health-title {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
		padding: 0;
		margin-bottom: 0;
		display: flex;
	}

	.workforce-last-generated {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-top: 5px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		height: 17px;
	}

	.workforce-health-note {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-top: 2px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}

	.developer-stats-cards-container {
		margin-top: 14px;
	}

	.developer-stats-table-container {
		margin-top: 15px;
	}

	.developer-metric-badge-filter-container {
		margin-top: 15px;

		.developer-metric-badge-filter-title {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			color: #000000;
		}
	}

	.workforce-health-export {
		display: flex;
		margin-left: 4px;

		a.workforce-health-export-label.ui.label {
			cursor: pointer;
			margin: 0;
		}

		.workforce-health-tooltip {
			display: inline-block;
			border-color: #8a97ae;
			color: #8a97ae;
			height: 15px;
			width: 15px;
			font-size: 8px;
			font-weight: 400;
			line-height: 13px;
			border: 1px solid;
			border-radius: 8px;
			margin: 0 0 3px 5px;
			cursor: help;
		}
	}
}
