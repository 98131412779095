.developer-badge-breakdown-empty-state {
	text-align: center;
	align-items: center;

	.developer-badge-breakdown-empty-state-image {
		margin-top: 123px;
	}

	.developer-badge-breakdown-empty-state-text {
		text-align: center;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
		width: 266px;
		height: 51px;
		margin-bottom: 123px;
		margin-top: 4px;
		display: inline-block;
	}
}
