@import "../colors.scss";

.icon-label,
.status-label {
    display: block;
    height: 19px;
    border: 0;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    .icon {
      margin: 0 4px 0 0;
      width: 14px;
      height: 14px;
    }

    &.green {
      border-color: #00bc95;
      color: #00bc95;
    }

    &.red {
      color: #D81E35;
    }

    &.gray {
	  color: #5C6C80;
	  border-color: #5C6C80;
      .icon.play {
        background-color: #5C6C80;
        color: #e7edf4;
        font-size: 7px;
        line-height: 15px;
        position: relative;
        top: -2px;
        left: 1px;
      }
    }

    &.black {
      color: #0F2345;
      border-color: black;
      .icon {
        margin-right: 2px;
      }
    }

    &.deep-blue {
      color: #170987;
      border-color: #170987;
      .icon:not(.check) {
        background-color: #170987;
        color: #e4e8ec;
        font-size: 9px;
        line-height: 15px;
        position: relative;
        top: -2px;
        left: 1px;
      }
    }

    &.blue {
      border-color: #3e6fa7;
      color: #3e6fa7;
      .icon {
        background-color: #3e6fa7;
        color: #e7edf4;
        font-size: 7px;
        line-height: 15px;
        position: relative;
        top: -2px;
        left: 1px;
      }
    }
  }

.ui.loader {
	z-index: auto !important;
}

.statuses-display {
	width: 100%;
	vertical-align: middle;
	display: inline-flex;

	div:first-child {
		width: 40%;
	}

	div:last-child {
		width: 50%;
	}

	.icon {
		height: 100%;
		align-self: center;
		font-size: 11px !important;
		font-family: 'outline-icons', serif !important;
	}
}

.status-icon {
	min-width: 17.5px;
}

.ui.label.task-removed {
	float: left;
	background-color: $acu-red-warning;
	color: white;
	font-size: 11px;
	line-height: normal;
	align-self: center;
}
