$color-light-gray: #dadde0;
$color-light-blue-gray: #ebeef2;
$color-gray: #ced5dc;

.align-items-baseline {
	align-items: baseline;
}

.space-left {
	margin-left: 1em;
}

.block {
	display: block;
}

.validationForm {
	width: 100%;
}

.ui.checkbox input[disabled] ~ label,
.disabled-style-text,
.checkbox.disabled{
	color: rgba(gray, 0.9) !important;
}

.disabled-style,
.disabled.dropdown,
textarea:disabled,
input:disabled {
	@extend .disabled-style-text;
	background-color:  rgba(gray, 0.05) !important;
	opacity: 1 !important;
}

.disabled.dropdown.label { 
	border-top: 1px solid rgba(gray, 0.2);
	border-bottom: 1px solid rgba(gray, 0.2);
	border-right: 1px solid rgba(gray, 0.2);
}

.disabled.multi-select{
	div[class*="-control"]{
		@extend .disabled-style;
	}
}

.ui.disabled.input {
	opacity: 1 !important;
}
