.developer-stats-table-aggregated-data-cell {
	padding-left: 1px;

	&:last-child {
		.developer-stats-table-aggregated-data-cell-content {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	.developer-stats-table-aggregated-data-cell-content {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;
		height: 100%;
		padding: 14px 17px;
		background-color: #DADEEC;

		.developer-stats-table-aggregated-data-cell-content-value {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;
			letter-spacing: 0.002em;
			color: #596566;
		}

		.developer-stats-table-aggregated-data-cell-content-unit {
			margin-left: 4px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 19px;
			display: flex;
			align-items: center;
			letter-spacing: 0.002em;
			color: #596566;
		}
	}
}
