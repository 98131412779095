@import "../../../components/colors.scss";

.burndown-chart-modal {
	width: 1200px !important;
	max-width: 95%;
	.burndown-chart-modal-container {
		padding: 16px;
		.burndown-chart-modal-title {
			font-size: 19px;
			color: $font-dark-blue;
			text-align: center;
			font-weight: bold;
			line-height: 1.28571429em;
			padding: 8px 0;
			margin-bottom: 8px;
		}
		.burndown-chart-modal-footer {
			color: $font-dark-blue;
			text-align: right;
			font-weight: 300;
			font-size: 14px;
			line-height: 1em;
			padding: 1.2rem 1rem 0 0;
			margin-bottom: 0.2rem;
		}
		.burndown-chart-modal-items {
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}
			&::-webkit-scrollbar-track {
				background: rgba(0, 0, 0, 0.1);
			}

			&::-webkit-scrollbar-thumb {
				background: rgba(19, 19, 19, 0.5);
			}

			height: 480px;
			width: 100%;
			overflow: auto;
			.burndown-chart-modal-table {
				tr td {
					height: 59px;
					line-height: 2em;
					cursor: default;
				}
				.bcm-item-key {
					margin-right: 8px;
				}
			}
		}
	}
}
