.pr-cycle-time-trends {

	.pr-cycle-time-trends-cards {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: 20px;

		> :not(:first-child) {
			margin-left: 17px;
		}
	}
}
