.custom-table-cell.custom-table-cell-data {
	height: 46px;
	padding-top: 1px;

	&:first-child {
		padding-right: 1px;
	}

	&:not(:first-child) {
		padding-left: 1px;
	}

	.custom-table-cell-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		padding: 5px 16px;
		background-color: #FFFFFF;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
	}

	&.variant-sticky {
		position: -webkit-sticky;
		position: sticky;
		left: 0;
		z-index: 9999;
		width: 231px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}

		.custom-table-cell-content {
			min-width: 295px;
			max-width: 295px;
			//padding: 0;
		}
	}
}
