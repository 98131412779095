.developer-metrics-table-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 16px;

	.developer-metrics-table-row-metric-name {
		min-width: 150px;
		max-width: 150px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		color: #0F2345;
	}

	.developer-metrics-table-row-current-value {
		min-width: 30px;
		max-width: 30px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		letter-spacing: 0.002em;
		color: #0F2345;
		margin-left: 90px;
	}

	.developer-metrics-table-row-change-rate {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-left: 30px;
		min-width: 50px;
		max-width: 50px;

		.developer-metrics-table-row-change-rate-value {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			display: flex;
			text-align: center;

			&.developer-metrics-table-row-change-rate-value-growth {
				color: #32B195;
			}

			&.developer-metrics-table-row-change-rate-value-decline {
				color: #E24B63;
			}
		}
	}
	.developer-metrics-table-row-change-rate-icon {
		text-align: left;
		margin-left: 30px;
	}
}
