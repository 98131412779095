.pr-filter-item {
	display: inline-block;
	line-height: 1;
	vertical-align: baseline;
	background-image: none;
	padding: 0.5833em 1.25em;
	color: rgba(0, 0, 0, .6);
	text-transform: none;
	font-weight: 400;
	border: 1px solid #D7DFE2;
	border-radius: 38px;
	color: #000000;
	background-color: #FFFFFF;
	cursor: pointer;

	.pr-filter-item-figure {
		display: inline-block;
		vertical-align: top;
		font-weight: 700;
		margin-left: 1em;
		opacity: .8;
	}
}
.pr-filter-item.selected {
	color: #FFFFFF;
	font-weight: 600;
	background-color: #925EFF;
	border: 0 solid transparent;
}
