@import "../../components/colors.scss";
@import "../../components/style.scss";

.badge-config-container {
	height: 100%;
	padding-top: 6px;
	padding-left: 21px;
	padding-right: 107px;
	color: $font-dark-blue;
}
.page-title {
	font-family: default-bold-font, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #000000;
	padding: 0;
	margin-bottom: 0;
	display: flex;
}

.header-container {
	margin-top: 30px;
	margin-bottom: 16px;
}
