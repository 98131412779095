.developer-badge-breakdown-drawer {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: -458px;
	width: 452px;
	height: 100%;
	background: white;
	box-shadow: 0 -1px 6px #CED5DF;
	border-radius: 4px;
	transition: 400ms ease-out;
	z-index: 100000;

	&.open {
		right: 0;
	}

	.developer-badge-breakdown-drawer-header {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding-top: 16px;
		padding-right: 20px;
		padding-left: 20px;

		.developer-badge-breakdown-drawer-header-close-button {
			border: none;
			padding: 5px;
			margin: 0;
			background-color: transparent;
			cursor: pointer;
		}
	}

	.developer-badge-breakdown-drawer-content {
		display: flex;
		flex-direction: column;
		flex: 1;

		.developer-performance-breakdown-container {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 0 27px;
			margin-top: 43px;
		}
	}
}
