@import "../colors.scss";
@import "../vars.scss";

.filter-dropdown-container {
  $width: 19.933rem;
  $min-height: 33.333rem;
  $max-height: 33.333rem;
  $padding-y: 0.533rem;
  $padding-x: 1.066rem;
  position: static;

  .ui.segment.filter-dropdown {
    $border-color: #CED5DC;
    position: absolute;
    z-index: $selectors-z-index;
    min-width: $width;
    max-width: $width;
    height: auto;
    font-size: 0.8rem;
    text-align: left;
    padding: 0;
    border: 0.066rem solid $border-color;
    border-radius: 0.266rem;
    box-shadow: 0 0.266rem 1.066rem transparentize($acu-retro-primary, 0.75);

    > .filter-dropdown-content {
      position: relative;
      display: flex;
      flex-direction: column;

      > .items-search-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0.066rem solid $border-color;

        .search-icon {
          width: 1.333rem;
          height: 1.333rem;
          margin-left: 1.066rem;
          background: url("../svg-assets/search-icon.svg") center center no-repeat;
        }

        input.items-search-input {
          width: 100%;
          height: 100%;
          font-size: 0.933rem;
          line-height: 1.333rem;
          border: none;
          padding: 0.666rem 1.066rem;
        }
      }

      > .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.666rem 1.066rem;
        border-bottom: 0.066rem solid $border-color;
        cursor: default;

        > .action-button {
          color: $acu-light-gray-text;
          font-size: 0.933rem;
          line-height: 1.333rem;
          background: transparent;
          border: none;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }

        > .action-button:not(:first-child) {
          margin-left: 0.533rem;
        }
      }

      > ul.items {
        flex: 1 1 0;
        padding: 0;
        margin: 0;
        max-height: 25rem;
        overflow-y: auto;

        > li.item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.766rem 0.933rem 0.766rem 1.066rem;
          box-sizing: border-box;
          list-style-type: none;

          &:hover {
            background-color: #F0F3F4;
          }

          .ui.checkbox > input ~ label::before {
            width: 1.066rem;
            height: 1.066rem;
            border: 0.066rem solid $acu-retro-dark-gray-text;
            box-sizing: border-box;
            border-radius: 0.133rem;
          }

          .ui.checkbox.checked > input:checked {
            ~ label::before {
              background: url("../svg-assets/bg-checkbox.svg") center center no-repeat;
              border-radius: 0.133rem;
              border: none;
            }

            ~ label::after {
              content: "";
              background: url("../svg-assets/checkbox.svg") center center no-repeat;
            }
          }

          .item-text {
            font-size: 0.933rem;
            line-height: 1.133rem;
            margin-left: 1.066rem;
          }

          .task-icon.task-icon-large {
            height: 24px;
            width: 24px;
          }

          .task-icon.task-icon-small {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
