.select-integration-provider-root {
	width: 100%;
	text-align: center;
	& .row {
		justify-content: center;
	}
}

.integration-provider-image-container {
	text-align: center;
	margin-bottom: 10px;
	//outline: 1px solid #04a9f5;
	min-width: 120px;
	max-width: 180px;
	min-height: 120px;
	padding: 50px 10px 10px;
	border: 5px solid transparent;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	border-radius: 10px;
	&.inactive {
		cursor: default;
		filter: grayscale(100%);
		img {
			opacity: 0.4;
		}
	}
	&:hover, &.selected {
		outline: 0 solid transparent;
		padding: 40px 10px 10px;
		border: 5px solid #04a9f5;
		&:after {
			background: #04a9f5;
		}
		.coming-soon-ribbon {
			opacity: 1;
		}
	}
	&:after {
		content: attr(data-alt);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 25px;
		background: transparent;
		transition: all 0.2s ease-in-out;
		color: #fff;
		font-size: 14px;
	}
}

.integration-provider-image {
	width: 80px;
}

.loading-indicator {
	position: relative;
	top: 0;
	height: 90px;
}

.coming-soon-ribbon {
	position: absolute;
	left: -5px; bottom: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px; height: 75px;
	text-align: right;
	transform: rotate(-90deg);
	transition: all 0.2s ease-in-out;
	opacity: 0;
}
.coming-soon-ribbon i {
	font-style: normal;
	font-size: 10px;
	font-weight: bold;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(135deg);
	//-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #79A70A;
	background: linear-gradient(#F70505 0%, #8F0808 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px; left: -21px;
}
.coming-soon-ribbon i::before {
	content: "";
	position: absolute; left: 0; top: 100%;
	z-index: -1;
	border-left: 3px solid #8F0808;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #8F0808;
}
.coming-soon-ribbon i::after {
	content: "";
	position: absolute; right: 0; top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #8F0808;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #8F0808;
}
