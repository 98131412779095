@import "../../../components/colors.scss";

.user-form {
	$mandatoryColor: rgba(#ea4f50, 0.5);
	.user-edit {
		margin-bottom: 1rem;
	}

	.user-avatar {
		width: 65px !important;
		height: auto !important;
	}
	.checkbox {
		z-index: 0;
	}
	label {
		font-weight: 600;
	}

	.create-team-button {
		padding-top: 3rem;
	}

	input.required {
		border-color: $mandatoryColor !important;
	}

	.message.required {
		color: $color-text-gray;
		font-size: 0.75rem;
	}

	.required {
		div[class*="css-"] {
			border-color: $mandatoryColor !important;
		}
	}
	.primary.button:disabled {
		opacity: 1 !important;
		cursor: not-allowed;
	}
	.pull-right {
		.primary.button {
			margin: 0px;
		}
	}

	.clickable {
		div[class*="-control"]:hover {
			cursor: pointer;
		}
	}

	input[type="checkbox"]:disabled, .checkbox:disabled {
		cursor: default !important;
	}

	input.disabled {
		background-color: hsl(0, 0%, 95%) !important;
		color: rgba(#052348, 0.4) !important;
	}

	.order-container-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
