@import "../../components/colors.scss";

.ui.button.retro-button {
	width: 100%;
	height: 100%;
	background: $acu-retro-secondary;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0.2rem;
	color: $color-white;
	font-weight: 600;
	font-size: 1.07rem !important;
	line-height: normal;

	&:hover {
		opacity: 0.9;
	}

	&:active {
		opacity: 0.8;
	}

	&.configurations {
		max-width: 6.67rem;
	}
}

.retro-link {
	border: none !important;
	color: $acu-retro-secondary !important;
	background-color: transparent !important;
	padding-left: 0em !important;

	&:hover {
		opacity: 0.9;
	}

	&:active {
		opacity: 0.8;
	}
}

.retro-link-secondary {
	border: none !important;
	color: $acu-light-gray-text !important;
	background-color: transparent !important;
	padding-left: 0em !important;
	font-weight: 600 !important;
	font-size: 1.07rem !important;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.7;
	}
}
.external-page {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	color: $acu-retro-primary;
	padding: 60px 32px 0;
	overflow: auto;

	&.loading-page {
		height: 100vh;
		padding: 0;

		> .loading-indicator::before, > .loading-indicator::after {
			width: 2.7rem;
			height: 2.7rem;
		}
	}

	.sprint-selector-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-shrink: 0;

		.selector-title {
			font-size: 36px;
			margin-bottom: 12px;
		}

		.selector-description {
			font-size: 20px;
			margin-bottom: 52px;
		}

		.sprints-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 12px;
			margin-bottom: 48px;
			@media only screen and (max-width: 600px) {
				grid-template-columns: 1fr;
			}

			.sprints-range-item {
				font-size: 16px;
				height: 43px;
				width: 272px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $acu-retro-primary;
				border-radius: 2px;
				cursor: pointer;
				&:hover {
					font-weight: bold;
				}
				&:active {
					background: $acu-retro-gray;
					font-weight: normal;
				}

				&.selected {
					background: $acu-retro-gray;
					&:hover {
						background: $acu-retro-gray;
						font-weight: normal;
					}
				}
			}
		}

		.selection-section {
			margin-bottom: 12px;

			.radio-button-wrapper {
				margin-bottom: 12px;
				text-align: left;
				label {
					color: $acu-retro-primary;
					font-size: 20px;
				}
			}
		}

		.button-wrapper {
			width: 200px;
			height: 43px;
			display: inline-block;
			align-self: center;
		}
	}
}

.loading-page-text {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	align-items: center;
	text-align: center;
	letter-spacing: -0.075px;
	padding-bottom: 0.5rem;

	&.bold {
		font-weight: 600;
	}
}

.loading-page-title {
	font-size: 32px;
	margin-top: 0;
}

.loader-margin {
	margin-top: 1rem !important;
}

.underline {
	text-decoration-line: underline;
	text-decoration-color: $acu-retro-primary;
}

.page-title {
	font-size: 2.35rem;
}

.status-color {
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.002em;
}

.status-default {
	&.status-bg { background-color: #C1CEDE; }
	&.status-color { color: #C1CEDE; }
}

.status-selected-for-development {
	&.status-bg { background-color: #B3AF8F; }
	&.status-color { color: #B3AF8F; }
}

.status-in-progress {
	&.status-bg { background-color: #447BD6; }
	&.status-color { color: #447BD6; }
}

.status-code-review {
	&.status-bg { background-color: #023E89; }
	&.status-color { color: #023E89; }
}

.status-to-test {
	&.status-bg { background-color: #7D98A1; }
	&.status-color { color: #7D98A1; }
}

.status-awaiting-qa {
	&.status-bg { background-color: #C9EFF7; }
	&.status-color { color: #C9EFF7; }
}

.status-qa-in-progress, .status-testing {
	&.status-bg { background-color: #48C9E3; }
	&.status-color { color: #48C9E3; }
}

.status-blocked {
	&.status-bg { background-color: #C61C31; }
	&.status-color { color: #C61C31; }
}

.status-done {
	&.status-bg { background-color: #00BC94; }
	&.status-color { color: #00BC94; }
}

.status-open {
	&.status-bg { background-color: #393D3F; }
	&.status-color { color: #393D3F; }
}

.status-closed {
	&.status-bg { background-color: #1F7C66; }
	&.status-color { color: #1F7C66; }
}

.status-discarded {
	&.status-bg { background-color: #FF7F51; }
	&.status-color { color: #FF7F51; }
}

.status-removed {
	&.status-bg { background-color: #FF678C; }
	&.status-color { color: #FF678C; }
}

.status-reassign {
	&.status-bg { background-color: #C994EF; }
	&.status-color { color: #C994EF; }
}

.status-reopened {
	&.status-bg { background-color: #FFB703; }
	&.status-color { color: #FFB703; }
}
