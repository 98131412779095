.trend {
	.trend-current-value {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		letter-spacing: 0.002em;
		color: #0F2345;
	}

	.trend-change-rate {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 1px;

		.trend-change-rate-value {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			display: flex;
			align-items: center;
			color: #9AAFB7;
		}
	}

	&.variant-bold {
		.trend-change-rate {
			.trend-change-rate-value {
				font-family: default-bold-font, sans-serif;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
			}
		}
	}

	&.trend-growth {
		.trend-change-rate-value {
			color: #32B195;
		}
	}

	&.trend-decline {
		.trend-change-rate-value {
			color: #E24B63;
		}
	}
}
