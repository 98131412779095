.org-metrics-card {
	.org-metrics-card-header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 14px 0 14px 11px;

		.org-metrics-card-header-text {
			margin: 0;
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: #000000;
		}
	}

	.org-metrics-card-items {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;
		min-height: 100%;

		> :not(:first-child) {
			margin-left: 15px;
		}

		.org-metrics-card-item {
			padding: 16px;
			background: #FFFFFF;
			border-radius: 4px;
			flex: 1;
			min-height: 100%;
		}
	}
}
