@import "../colors.scss";

$line-height: 34px;

.config-field-label {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 15px;
}

.acu-capitalize::first-letter {
	text-transform: capitalize;
}

.configurations-form {
	$primary-text: #0f2345;
	color: $primary-text;
	width: 750px !important;

	.ui.button {
		text-transform: none;
	}

	p,
	.header,
	.text-color,
	.acu-select {
		color: $primary-text;
	}

	.icon {
		font-size: inherit !important;
	}
	.scrolling {
		max-height: 500px !important;
		min-height: 500px !important;
		height: 700px !important;
	}
	.content {
		overflow: hidden;
		.scrolling {
			max-height: 250px;
			min-height: 250px;
			overflow-y: auto;
			overflow-x: hidden;
			.scroll-space {
				width: 680px;
			}
		}

		.header,
		.card-header {
			padding: 1rem;
			.message {
				font-size: 1rem;
				color: $color-text-gray;
			}
		}

		.side-label {
			@extend .text-color;
			font-size: 15px;
			white-space: nowrap;
			white-space: normal;
			line-height: 1em;
			display: table;
			height: 2rem;
			span {
				display: table-cell;
				vertical-align: middle;
			}
		}

		.input-field {
			width: 380px;
			max-width: 380px;
			min-width: 380px;
			height: 100%;
		}

		.date-input {
			.styled-date-picker {
				width: 220px;
				height: $line-height;
				border-radius: 3px !important;
				line-height: 1rem;
				input {
					text-align: center !important;
					background-color: $acu-dark-blue !important;
				}
			}
		}

		.field-group {
			width: 100%;
			padding: 0.75rem 0rem;
			margin-bottom: 1rem;
			.acu-input {
				padding-left: 1rem;
				border: 0px solid transparent !important;
			}
			.small-text {
				min-height: 2rem;
			}
		}

		.acu-input {
			margin-bottom: 1.75rem;
			background-color: white;
			.acu-text-input,
			input {
				border-radius: 2px;
				border: 1px solid $acu-dark-blue !important;
				height: $line-height;
				&.--error {
					background-color: #fff6f6;
					border: 1px solid #e0b4b4 !important;
				}
			}
			.float-right {
				float: right;
				.label {
					display: none;
				}
			}
		}

		.acu-input.bottom-margin {
			margin-bottom: 1.75rem;
		}

		.acu-labeled-input {
			width: 220px !important;
			input {
				border-radius: 2px 0px 0px 2px !important;
			}
			.ui.input.--error {
				input {
					background-color: #fff6f6;
					border: 1px solid #e0b4b4 !important;
				}
			}
			.label {
				color: $acu-placeholder-gray;
				border-radius: 0px 2px !important;
				border-left: hidden;
				height: $line-height;
				cursor: default;
				padding: 0.5rem 0.75rem !important;
				min-width: 90px;
			}
		}
	}

	.actions {
		border-color: $acu-border-gray !important;
	}

	.acu-select-input-container {
		margin-bottom: 2rem;
	}

	.control-width {
		max-width: 200px !important;
		min-width: 200px !important;
	}

	.actions {
		background-color: white !important;
		min-width: 600px;
	}

	.button.primary.apply {
		color: white;
		background-color: $acu-light-purple;
		border: 1px solid $acu-light-purple;
	}

	.button.primary:hover,
	.button.primary:active {
		font-weight: 600;
		background-color: $acu-shadow-purple;
		border: 1px solid $acu-shadow-purple;
	}

	.secondary.button,
	.secondary.button:focus {
		color: $acu-light-purple;
		background-color: white;
		border: 1px solid $acu-light-purple;
	}

	.secondary.button:hover,
	.secondary.button:active {
		color: $acu-shadow-purple;
		background-color: white;
		border: 1px solid $acu-shadow-purple;
	}

	.link-button {
		color: $acu-light-purple !important;
	}

	.link-button:hover {
		color: $acu-shadow-purple !important;
	}

	.right-buttons {
		display: flex;
		flex-direction: row-reverse;
		.button {
			font-size: 14px;
			margin-left: 1rem;
			min-width: 90px;
		}
	}

	.ui.checkbox.acu-checkbox input:checked ~ label::before {
		background-color: $acu-light-purple !important;
		border-color: $acu-light-purple !important;
	}

	.ui.popup {
		padding: 5px 5px 0px 5px !important;
		background-color: $acu-dark-blue;
	}
}

.configurations-form-fields-container {
	& > div {
		padding: 1rem 0px;
	}
	.input-field {
		padding: 2px 0px;
		input {
			padding-left: 12px;
		}
	}
	.card-header{
		.small-text{
				line-height: 1rem ;
		}
	}
}

.customization-icon-nav {
	direction: rtl;
	display: flex;
	width: 100%;
}

.padded-icon {
	padding: 1px 0px 1px 0px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
	background-color: $acu-light-purple !important;
}

.react-datepicker__day--keyboard-selected:hover {
	background-color: $acu-light-purple !important;
}

.go-retro-configurations .form-control.configuration-dynamic-multiselect-control {
	align-items: flex-start;
}
