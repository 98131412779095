.developer-completed-work-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 16px;

	.developer-completed-work-item-public-identifier {
		font-weight: 600;
		font-size: 14px;
		min-width: 111px;
		max-width: 111px;
		display: flex;
		align-items: center;
		color: #0F2345;
	}

	.developer-completed-work-item-title {
		font-weight: 400;
		font-size: 14px;
		display: flex;
		align-items: center;
		color: #0F2345;
	}

	.developer-completed-work-item-img {
		margin-right: 20px;
		text-align: center;
		padding-top: 3px;
	}
}
