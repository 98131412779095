.team-info-cell-content {
	cursor: pointer;
	width: 100%;

	.team-info-cell-content-name {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.002em;
		color: #0F2345;
	}

	.team-info-cell-content-count {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.002em;
		color: #596566;
	}

	.team-info-cell-content-badges {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		position: absolute;
		width: 100px;
		top: 0;
		right: 0;

		> :not(:first-child) {
			margin-top: 1px;
		}
	}
}
