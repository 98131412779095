th.custom-table-cell-header {
	min-width: 175px;
}

.custom-table-cell-content-container {
	max-height: 100%;
	overflow: hidden;
}

.custom-table-cell.custom-table-cell-header {
	height: 74px;
	max-width: 150px;

	&:first-child {
		padding-right: 1px;

		.custom-table-cell-content-container {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}

	&:not(:first-child) {
		padding-left: 1px;
	}

	&:last-child {
		.custom-table-cell-content-container {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	&.variant-sticky {
		position: -webkit-sticky;
		position: sticky;
		z-index: 150;

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}
	}

	.custom-table-cell-content-container {
		height: 100%;
		padding: 9px;
		background-color: #F9FBFF;

		.custom-table-cell-content {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			.custom-table-cell-content-title-container {
				text-align: left;

				.custom-table-cell-content-title {
					font-family: default-bold-font, sans-serif;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					color: #0F2345;
				}

				.custom-table-cell-content-subtitle {
					font-family: default-regular-font, sans-serif;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					color: #0F2345;
				}
			}

			.custom-table-cell-content-controls {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;

				.custom-table-cell-content-sort-controls {
					&:not(.variant-active) {
						visibility: hidden;
					}
				}

				.custom-table-filter-select {
					width: auto;
					max-width: 115px;
					margin-left: 10px;

					.custom-table-filter-select-trigger {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						width: 24px;
						min-width: 24px;
						height: 24px;
						background-color: #FFFFFF;
						box-shadow: 0px -1px 6px #CED5DF;
						border: none;
						border-radius: 50%;
						transition: 100ms ease-in-out;

						&:hover, &.variant-active {
							background-color: rgba(0, 0, 0, 0.05);
						}
					}
				}
			}

			&:hover {
				.custom-table-cell-content-controls > .custom-table-cell-content-sort-controls {
					visibility: visible;
				}
			}
		}
	}
}

.custom-table-header-tooltip {
	max-width: 240px;
}
