.tabs {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;

	.tabs-border-filler {
		flex: 1;
		height: 38px;
		border-bottom: 1px solid #DDDDDD;
	}
}
