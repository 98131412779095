.dev-stats-column-config-modal {
	max-width: 700px;

	.modal-header-title {
		font-family: default-bold-font, sans-serif;
	}

	.category-title {
		margin-bottom: 10px;
		font-weight: bold;
		color: #854BFE;
	}

	.metric-checkbox {
		padding: 10px;
	}
}