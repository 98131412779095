.team-detailed-profile {
	padding: 56px 26px 0;

	.team-detailed-profile-header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.team-detailed-profile-info {
			margin-left: 19px;

			.team-detailed-profile-info-name {
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 29px;
				letter-spacing: 0.002em;
				color: #0F2345;
			}

			.team-detailed-profile-info-members-count {
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.002em;
				color: #596566;
			}

			.team-detailed-profile-info-members-avatars {
				padding-top: 17px;
				padding-bottom: 17px;
			}
			.team-detailed-profile-info-see-workforce-breakdown {
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				text-decoration-line: underline;
				color: #9AAFB7;
			}
			.team-detailed-profile-info-badges {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				margin-top: 13px;

				> :not(:first-child) {
					margin-left: 4px;
				}

				.badge {
					margin-bottom: 1px;
				}
			}
		}
	}
}
