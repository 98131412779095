@import "../../../components/colors.scss";

#iteration-review {
	.ui.raised.card {
		border-radius: 0px !important;
		box-shadow: 0 0 0 0px $color-gray, 0 0px 0px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
		padding: 2rem;
		flex-flow: column;
	}
	$small-icon-size: 20px;
	$tiny-icon-size: 13px;

	.burndown-container {
		padding: 20px 50px;
		.noContent {
			img {
				margin-top: -4.5rem;
			}
		}
	}

	.highcharts-container {
		margin: auto;
	}

	.reports.content {
		padding: 0px;
	}

	.issues-table {
		.use-placeholder,
		.acu-scroll {
			height: 42rem;
		}
	}

	.header {
		color: $color-darkest-blue;
	}

	.right-side-controls {
		.control-width {
			min-width: 315px;
		}
	}

	.card > .table,
	.table {
		border: none;
		justify-content: initial;
		i.icon.chevron {
			font-size: $small-icon-size;
			vertical-align: baseline;
		}
		.icon.acumen-icon {
			width: $small-icon-size !important;
			height: $small-icon-size !important;
			display: flex;
			background-color: $color-darkest-blue;
			border-radius: 30px;
			img {
				margin: auto;
				width: $small-icon-size - 6px;
				height: $small-icon-size - 6px;
			}
		}
		.icon.acumen-icon {
			color: $color-bg-light-gray;
		}
		tr.selected {
			td {
				background-color: $color-semantic-blue-background;
			}
		}
		&.inner {
			border: initial;
			tr.thead {
				td {
					border-top: 1px solid rgba(34, 36, 38, 0.1);
					font-weight: 600 !important;
					background-color: $color-semantic-blue-background;
				}
			}
			tr {
				td {
					background-color: $color-bg-light-gray;
					height: 35px;
					padding: 0.5em auto;
					span {
						font-weight: 400 !important;
					}
				}
			}
			tr:hover {
				td,
				td > span {
					background-color: $color-bg-mid-gray;
				}
			}
		}
		&.min-width {
			td {
				div {
					min-width: 2em;
				}
			}
			th {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	th {
		background-color: $color-semantic-blue-background;
		border: none;
	}
	tr {
		td {
			height: 59px;
			line-height: 2em;
			.acumen-icon,
			.icon {
				text-align: center;
			}
			.icon.active {
				color: $color-darkest-blue;
			}
			.icon.tiny {
				color: $color-darkest-blue;
				font-size: $tiny-icon-size;
			}
		}
		.task-title:after {
			content: "\00a0\00a0\00a0\00a0\00a0\00a0 ";
		}
	}

	tfoot {
		td {
			background-color: white;
		}
	}

	span.colored-text {
		&.critical {
			color: #ff3a53;
		}
		&.high {
			color: $color-warning;
		}
		&.mid {
			color: $color-orange;
		}
		&.low {
			color: $color-yellow;
		}
	}

	.pr-icon-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 90px;
	}
	.retro-highlights {
		font-weight: 400;
		padding: 1rem;
		p {
			margin: 0px;
		}
		.highlight-value {
			font-size: 35px;
			color: $font-dark-blue;
		}
		.changePercentage {
			margin-left: 0.5rem;
			&.primary {
				color: $acu-positive-trend;
			}
			&.warning {
				color: $color-red;
			}
		}
		.highlight-title {
			font-size: 20px;
			color: #5a74a0;
		}
	}

	.previous-sprint {
		padding: 25px 15px 0px 15px;
		font-size: 12px;
		font-weight: 400;
		color: #6f82999c;
	}

	.noContent {
		margin: auto;
		p {
			margin: 0px auto;
			font-size: 1.25em;
			color: $color-text-gray;
		}
		img {
			margin: auto;
		}
	}

	.ui.segment.tab:last-child {
		margin-bottom: 0;
	}

	.ui.tab {
		padding: 0;
		border: none;
		box-shadow: none;
	}
}

.tooltip-container {
	width: 16.67rem;
	border-radius: 0.53rem;
}

.tooltip-header {
	padding: 0.8rem 1rem;
	background-color: $acu-retro-gray;
	border-radius: 0.13rem 0.13rem 0 0;
	white-space: initial;

	h5 {
		font-size: 0.86rem;
		font-weight: 600;
	}
}

.tooltip-row {
	display: flex;
	margin: 1rem !important;

	.series-name {
		display: inline-block;
		width: 10.2rem;
	}

	.bullet {
		display: inline-block;
		min-width: 0.53rem;
		max-width: 0.53rem;
		height: 0.53rem;
		border-radius: 50%;
		margin: 0.2rem 0.8rem 0 0;
	}
}
