@import "../../components/colors.scss";
@import "../../components/style.scss";

.panel-header {
	padding: 0.75rem !important;
	font-weight: 600;
}

.full-height {
	height: 100%;
}

.subtitle {
	margin-top: -0.75rem;
}

.task-icon-items {
	display: flex;
    justify-content: space-around;
    align-items: center;
}

.gray-font {
	color: $color-text-gray;
}

.side-margin {
	margin: 0 1rem;
}

.label.transparent {
	background-color: transparent;
}

.table.fixed {
	.wide.half {
		width: 3.125%;
	}

	.no-ellipsis {
		overflow: visible !important;
	}
	thead {
		white-space: normal;
	}
}

.subheader,
.header {
	white-space: normal;
	overflow-wrap: anywhere;
}

.acu.label {
	background-color: $color-bg-blue-gray;
	color: $font-dark-blue;
	vertical-align: sub;
	margin: 0px;
	&.tiny {
		font-size: 10px;
	}
}

.level {
	span,
	img,
	div {
		vertical-align: middle;
		display: inline-block;
	}
}
.level > * {
	margin: 0px 3px;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.baseline {
	align-items: baseline;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

.right-side-controls {
	display: flex;
	justify-content: flex;
	flex-flow: row;
	float: right;
	font-size: 1.5rem;
	z-index: $selectors-z-index;
}

.controls.ui.row {
	align-items: center;
}

.tasks-column {
	color: $color-dark-blue;
	background-color: $color-bg-blue-gray;
	padding: 1em;
	margin-bottom: 50px;
}

.inline-pad > * {
	margin: 0rem 2px;
}

.no-wrap {
	white-space: nowrap;
}

.hidden-overflow {
	overflow: hidden;
}

.my-team-page {
	height: calc(100vh - 2rem);
}

.hide-horizontal-overflow {
	overflow-x: hidden;
}

///////////////  TODO(ACM-3006) - clean styles of semantic image
.ui.micro.image {
	width: 16px;
	height: 16px;
}

.card.full-width,
.full-width {
	width: 100%;
	width: -moz-available; /* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
	width: stretch;
}

.task.card {
	width: auto;
	border-radius: 1px;
	font-size: 15px;
	padding: 0px !important;

	.extended-info {
		font-size: 13.5px;
		background-color: $color-semantic-blue-background;
		padding: 0.5rem 0.75rem;
		border-bottom: 1px solid $color-bg-blue-gray;
		cursor: default;
	}
	.marker {
		font-size: 1.5rem;
		$marker-width: 12px;
		width: $marker-width;
		left: -$marker-width;
		position: absolute;
		height: 100%;
		padding: 0px 0.5px !important;
		border-radius: 0px !important;
		.arrows {
			padding-top: 0.95rem;
		}
		.white {
			color: white;
		}
		.icon:first {
			padding-top: 1rem;
		}
		.icon {
			padding: 0px 0.5rem 1.5rem 0px;
		}
	}
	.marker.primary {
		background-color: $color-primary;
	}
	.marker.warning {
		background-color: $color-red;
	}
	.marker.extreme-primary {
		background-color: $color-dark-primary;
	}
	.marker.extreme-warning {
		background-color: $color-warning-visited;
	}
	.label {
		text-transform: capitalize;
	}
	.ui.image.avatar.medium {
		width: 26px;
		height: 26px;
	}
	.limit-width {
		@media screen and (max-width: 1500px) {
			max-width: 100px;
		}
	}
	&.selected {
		background-color: $color-semantic-blue-background;
		.extended-info {
			background-color: $color-gray;
		}
	}
}

.task.card:hover {
	box-shadow: 0 0 0 1px #ced5dc, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.loading.placeholder {
	z-index: 0;
}

.right-side-controls {
	text-align: left;
	font-size: 1rem;
	display: flex;
	justify-content: flex;
	flex-flow: row;
	float: right;
	.control-width {
		min-width: 350px;
		padding-left: 0.25rem;
	}
	.control-width.small {
		min-width: 180px;
	}
}

.ellipsis-menu {
	cursor: pointer;
	font-size: 1.5rem;
}

.align-items {
	height: 100%;
	display: flex;
	place-items: center;
	.icon {
		line-height: 100%;
	}
	.task.card:hover {
		box-shadow: 0 0 0 1px #ced5dc, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
	}
}

.filters-subheader {
	vertical-align: middle;
	font-size: 1rem;
	margin-left: 1rem;
}

.labeled-header {
	display: flex;
	flex-direction: row;
	.label-margin {
		margin-right: 1rem;
		margin-left: 3px;
		align-self: center;
	}
	.label {
		color: #fff;
		padding: 3px;
		background-color: #adb8c2;
	}
}

.icon-label {
	@extend .flex-row;
	@extend .no-wrap;
	@extend .ellipsis;
	img {
		padding-right: 0.25em;
	}
	text-transform: capitalize;
	height: 100%;
}

.info.card {
	width: auto;
	border-radius: 1px;

	.right.floated {
		span,
		img {
			margin: 0px 3px;
		}
	}
	.level {
		min-height: 1.5rem;
		span,
		img,
		div {
			vertical-align: middle;
			display: inline-block;
		}
	}
}

.ui.card,
.ui.card > .content > .header,
.modals > .modal {
	.fullscreen-button {
		float: right;
		font-size: 0.6em;
		cursor: pointer;
	}
	.chart-tooltip {
		display: inline-block;
		border-color: #8a97ae;
		color: #8a97ae;
		height: 15px;
		width: 15px;
		font-size: 8px;
		font-weight: 400;
		line-height: 13px;
		border: 1px solid;
		border-radius: 8px;
		margin: 0 0 3px 5px;
		cursor: help;
	}
	.chart-export {
		cursor: pointer;
	}
}

.img-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	img,
	p {
		margin: 0px auto;
	}
}

.horizontal-separator-on-left {
	display: block;
	background-color: rgba($color-text-gray, 0.3);
	width: 1px;
	margin: 0.25em 0px;
}

.empty-team-selector {
	display: inline-block !important;
}

.team-selector {
	display: inline-block;
}

.hand-cursor-on-hover:hover {
	cursor: pointer;
}

.align-button {
	height: 100% !important;
	display: inline !important;
	padding: 0.5rem;
}

.set-popup-menu-z-index {
	z-index: 999 !important;
}

.ongoing-pages {
	.accordion {
		.toggler-icons {
			font-size: 11px;
			font-family: "outline-icons", serif;
		}
		.accordion-section-title {
			font-size: 16px;
			font-weight: 600;
			letter-spacing: -0.2px;
			line-height: 21px;
			color: $font-dark-blue;
		}
		.title {
			height: 77px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.table {
		th {
			height: 36px !important;
			font-size: 14px;
			font-weight: 600 !important;
			letter-spacing: -0.18px;
			line-height: 19px;
		}
		td {
			color: #0f2345;
			font-size: 16px;
			letter-spacing: -0.2px;
			line-height: 21px;
			.acu-link {
				border-bottom: 1px solid $acu-light-purple;
			}
		}
	}
}

.table-tab-menu {
	.item,
	.active.item {
		color: $color-darkest-blue !important;
	}
}

.table-placeholder {
	margin-top: 1rem;
}
