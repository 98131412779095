@import "../../../../components/colors.scss";

.integration-flow {
	.header{
		font-weight: 200;
	}
	.steps {
		min-height: 2rem;
		.step {
			padding: 0.5rem;
		}
		.step:first-child {
			padding-left: 0.5rem;
		}
		.steps-header {
			padding: 0.5em;
			justify-content: left;
			img,
			.title-icon {
				height: 2.25rem;
				width: 2.25rem;
				min-height: 2.25rem;
				min-width: 2.25rem;
			}
			.content {
				padding-left: 0.75rem;
			}
		}
		.step.active {
			width: initial;
			align-content: center;
			.title {
				color: $color-primary;
			}
		}
	}
	.description-box {
		min-height: 36vh;
		justify-content: space-between;
	}
	.description {
		font-size: 1.25rem;
		.step-action {
			margin: auto;
			width: initial;
			align-content: center;
		}
		.copy-input{
			display: flex;
			justify-content: center;
			.label {
				width: 550px;
			}
			.button{
				max-width: 120px;
			}
		}
		.ui.form {
			width: 75%;
			margin: auto;
		}
	}
	.actions.step-buttons {
		justify-content: space-between;
	}
	.big-button-style {
		margin: auto !important;
		padding: 2rem;
		.padded-span {
			padding: 0px 10px;
		}
		input {
			width: 400px;
		}
	}
	.flex {
		display: flex;
	}
}
.all-integrations {
	& .table {
		.active {
			color: $color-primary;
		}
		tr .options * {
			visibility: hidden;
		}
		tr:hover .options * {
			visibility: visible;
		}
		.image.in-table {
			width: 2rem;
			height: 2rem;
			margin-right: 0.5rem;
		}
		.creator-cell {
			display: flex;
			flex-direction: row;
			span:nth-child(2) {
				font-size: 0.75rem;
				line-height: 5px;
			}
		}
	}
}
