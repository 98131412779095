@import "../../components/colors.scss";

.slack-notifications {
	.form-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 23px;
		.form-title {
			font-size: 28px;
			color: $font-dark-blue;
			margin-bottom: 0px;
		}
	}
	.accordion {
		border-radius: 3px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 5px 0 #CED5DF;
		.ui.segment.raised {
			margin: 0;
			padding: 0;
			box-shadow: none;
			border: none;
			border-bottom: 1px solid $acu-border-gray;
			&:last-child:not(:only-child) {
				border-bottom: none;
			}
			.title {
				display: flex;
				padding: 0 26.5px;
    			height: 78px;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: -0.2px;
				.header {
					font-size: 16px;
					display: flex;
					align-items: center;
					i.icon {
						display: flex;
						align-items: center;
						font-size: 11px;
						font-family: 'outline-icons';
						margin-right: 12px;
					}
				}
			}
			.content {
				padding: 32px 56px 50px 56px !important;
				background-color: $color-white;
				margin: 0;

				.slack-notification-wrapper {
					display: flex;
					flex-wrap: wrap;

					.slack-notification-form {
						flex: 60;
						border-right: 1px solid $acu-border-gray;
						padding: 0 32px 8px 0;
						.section-separator {
							display: flex;
							flex-direction: column;
							min-height: 50px;
							padding-left: 22px;
							border-left: 2px dashed transparent;
							&.dashed {
								border-left: 2px dashed $acu-border-gray;
							}
							.section-title {
								font-size: 18px;
								font-weight: bold;
								letter-spacing: -0.4px;
								height: 24px;
								color: $font-dark-blue;
								position: relative;
								margin-bottom: 32px;
								.section-mark {
									height: 17px;
									width: 17px;
									border: 1px solid $font-dark-blue;
									border-radius: 100%;
									position: absolute;
									left: -31px;
									background-color: $color-white;
									top: 0;
									&.checked {
										background-color: $font-dark-blue;
										.icon {
											content: "\2714\0020";
											color:  $color-white;
											font-size: 9px;
											display: flex;
											align-items: center;
											justify-content: center;
											height: 100%;
											width: 100%;
										}
									}
								}
							}
							.section-row {
								display: grid;
								grid-template-columns: repeat(2, 1fr);
								grid-column-gap: 10px;
								margin-bottom: 24px;
								flex-wrap: wrap;
								.section-row-item {
									flex: 1;
									min-width: 50%;
								}
							}
							.acumen-tooltip {
								height: 34px;
								width: 100%;
								border-radius: 3px;
								background-color: #F0F3F7;
								display: flex;
								align-items: center;
								padding: 7px 12px;
								margin-bottom: 24px;
								margin-top: -12px;
							}
						}
					}

					.slack-notification-preview {
						flex: 40;
						padding-left: 32px;
						display: flex;
						flex-direction: column;

						.notification-preview-title {
							color: $font-dark-blue;
							margin-bottom: 32px;
							font-size: 18px;
							letter-spacing: -0.4px;
							font-weight: bold;
						}

						.notification-preview-nothing-to-show-text {
							color: $font-dark-blue;
							font-size: 18px;
							letter-spacing: -0.4px;
							.icon {
								font-size: 15px;
							}
						}

						.notification-preview-nothing-to-show {
							display: flex;
							align-items: center;
							justify-content: center;
							flex: 1;
							min-height: 50px;
							width: 100%;
							img {
								height: 240px;
								width: 263.48px;
							}
						}

						.notification-preview {
							padding-left: 28px;
							position: relative;
							.notification-preview-time {
								font-size: 10px;
								color: $acu-small-font-gray;
								letter-spacing: -0.06px;
								position: absolute;
								top: 0;
								left: 0;
							}
							.notification-preview-header {
								.notification-preview-header-title {
									font-size: 24px;
									font-weight: 600;
									letter-spacing: -0.3px;
									color: $font-dark-blue;
									margin-bottom: 12px;
									padding: 0;
									line-height: normal;
								}
								.info-section {
									display: flex;
									padding-bottom: 12px;
									border-bottom: 1px solid $acu-border-gray;
									margin-bottom: 16px;
									.icon {
										height: 20px;
										width: 20px;
										color: $acu-light-purple;
									}

									.info-section-text {
										color: $font-dark-blue;
										font-size: 14px;
										letter-spacing: -0.08px;
										line-height: 19px;
										padding: 0 8px;
										&.bold {
											font-weight: bold;
										}
										&:not(:last-child) {
											border-right: 1px solid $acu-border-gray;
										}
									}
								}
							}
							.notification-preview-body {
								.notification-preview-body-title {
									.icon-font {
										font-size: 25px;
										display: inline;
									}
									color: $font-dark-blue;
									font-size: 16px;
									font-weight: 600;
									letter-spacing: -0.2px;
									margin-bottom: 16px;
								}

								.notification-section-box {
									margin-bottom: 28px;
								}

								.notification-preview-plain-text {
									color: $font-dark-blue;
									font-size: 16px;
									letter-spacing: -0.2px;
									margin-bottom: 18px;
									flex-shrink: 0;
								}

								.notification-preview-body-text {
									color: $font-dark-blue;
									font-size: 16px;
									letter-spacing: -0.2px;
									margin-bottom: 18px;
									flex-shrink: 0;
									margin-right: 8px;
									position: relative;
									&::before {
										content:"\A";
										width: 6px;
										height: 6px;
										border-radius: 50%;
										top: 7px;
										display: inline-block;
										background: black;
										margin-right: 7px;
									}
								}

								.notification-preview-fake-url {
									display: inline;
									color: #456b99;
									cursor: pointer;
								}

								.notification-preview-body-line {
									height: 4px;
									width: 100%;
									flex: 1;
									background-color: $acu-border-gray;
								}

								.notification-preview-section {
									display: flex;
									margin-bottom: 12px;
									align-items: baseline;
									flex-direction: column;
								}

								.notification-preview-row {
									display: flex;
									margin-bottom: 1px;
									align-items: baseline;
									flex: 1;
									width: 100%;
								}

								.notification-preview-tag {
									border: 1px dashed $acu-border-gray;
									border-radius: 3px;
									background-color: #F8F8F8;
									padding: 2.5px 12px;
									color: $acu-coral-red;
									display: inline;
									&.ml-8 {
										margin-left: 8px;
									}
									&.mr-8 {
										margin-right: 8px;
									}
								}

								.ticket-number {
									margin-right: 8px;
									display: inline;
									&.text-blue {
										color: #05B2DC;
									}
								}
							}
						}
					}
				}
			}
		}
		.accordion-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
		.accordion-title {
			display: flex;
			flex-direction: row;
		}

		.form-footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			.form-footer-center {
				flex-grow: 1;
			}
			.form-footer-actions {
				display: flex;
				.form-footer-separator {
					border-left: 1px solid $acu-border-gray;
					width: 1px;
					margin: 0 18px;
					height: 40px;
				}
				.form-toggle {
					display: flex;
					align-items: center;
					.form-toggle-input {
						display: flex;
						div {
							width: 40px !important;
							label {
								&::before {
									width: 40px !important;
									height: 20px !important;
									top: 3px;
									background-color: rgba(0, 0, 0, 0.15);
								}
								&::after {
									width: 18px !important;
									height: 18px !important;
									top:  4px;
								}
							}
						}
						.checked {
							label {
								&::before {
									background-color: $acu-light-purple !important;
								}
								&::after {
									left: 22px;
								}
							}
						}
					}
					.form-toggle-label {
						margin-left: 6px;
						color: $font-dark-blue;
						font-size: 16px;
						letter-spacing: -0.2px;
						line-height: 21px;
					}
				}

				button:not(:last-child) {
					margin-right: 8px;
				}

			}
		}

	}
	.ui.message {
		color: $font-dark-blue;
		background-color: $color-bg-light-gray;
		display: grid;
		grid-template-columns: fit-content(15px) auto;

		p {
			margin: 0;
		}
	}
}
