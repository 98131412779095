.reports-tab {
	label {
		margin-right: 30px;
	}
	.controls.controls {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.ui.card > .content > .header,
	.modals > .modal {
		color: #0f2345;
		font-weight: bold;
		font-size: 20px;
		.fullscreen-button {
			margin: 10px 10px 0 0;
		}
	}
}

#portal-root {
	.chart-tooltip {
		display: inline-block;
		border-color: #8a97ae;
		color: #8a97ae;
		height: 15px;
		width: 15px;
		font-size: 8px;
		font-weight: 400;
		line-height: 13px;
		border: 1px solid;
		border-radius: 8px;
		margin: 0 0 3px 5px;
		cursor: help;
	}
	.chart-export {
		cursor: pointer;
	}
}

.settings,
.filters {
	.search-input.search-input.search-input {
		display: inline-block;
		width: 0;
		transition: width 0.1s ease-in-out;
		color: #052348;
		border-color: #052348 !important;
		border-style: solid;
		border-width: 0 0 1px 0;
		&::placeholder {
			color: #bec8d9;
		}
		&.visible {
			width: 240px;
		}
	}
}

.fs-chart-modal {
	max-width: 95%;
	@media only screen and (min-width: 1200px) {
		width: 1200px !important;
	}
}

.expanded-chart{
	&.container{
		padding: 2.75rem;
	}
	h2.header {
		margin-top: 0rem;
	}
	.icon {
		cursor: pointer;
	}
}

.close-icon {
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
}

.no-top-margin {
	margin-top: 0px;
}
