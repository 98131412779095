.cycle-time-breakdown-target {
	width: 0;
	height: 0;
}

.cycle-time-breakdown-trigger {
	cursor: pointer;

	&:hover > .cycle-time-breakdown-overlay {
		display: block;
	}
}

.cycle-time-breakdown-overlay {
	width: 436px;
	display: none;
	position: fixed;
	z-index: 99999;
}

.cycle-time-breakdown-container {
	width: 436px;
	display: flex;
	flex-direction: column;
	align-items: center;

	> .cycle-time-breakdown-arrow {
		width: 30px;
		height: 15px;
		display: none;

		&.cycle-time-breakdown-arrow-top {
			display: block;
			background: #FFFFFF;
			clip-path: polygon(0 0, 50% 100%, 100% 0);
			transform: translateX(43.6px) translateY(-1px);
		}

		&.cycle-time-breakdown-arrow-bottom {
			display: block;
			background: #D5DFE2;
			clip-path: polygon(50% 0, 0 100%, 100% 100%);
			transform: translateX(43.6px) translateY(1px);
		}
	}
}

.cycle-time-breakdown-progress {
	width: 182px;
	flex: 1;
	padding: 17.5px 16px;

	> .cycle-time-breakdown-progress-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		overflow: hidden;
		border-radius: 5px;

		> .cycle-time-breakdown-progress-phase {
			min-width: 5px;
			height: 5px;

			&:first-child {
				border-radius: 5px 0 0 5px;
			}

			&:last-child {
				border-radius: 0 5px 5px 0;
			}

			&:first-child:last-child {
				border-radius: 5px;
			}

			&:not(:first-child) {
				padding-left: 1px;
			}

			> .cycle-time-breakdown-progress-phase-indicator {
				width: 100%;
				height: 100%;

				&.cycle-time-breakdown-default {
					background-color: #E1E8F0;
				}
			}
		}
	}
}

.cycle-time-breakdown {
	width: 100%;
	background-color: #FFFFFF;
	border-radius: 6px;
	box-shadow: 0 4px 16px rgba(20, 46, 90, 0.25);

	.cycle-time-breakdown-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 40px;
	}

	.cycle-time-breakdown-status {
		width: 154px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.002em;
		padding: 0 16px;
	}

	.cycle-time-breakdown-duration {
		width: 100px;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		letter-spacing: 0.002em;
		color: #0F2345;
		padding-left: 16px;
		padding-right: 20px;

		&.strong {
			font-weight: 600;
		}
	}

	.cycle-time-breakdown-divider {
		height: 1px;
		background: #CED5DC;
		border: none;
		margin: 8px 16px;
	}

	> .cycle-time-breakdown-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 19px 16px;
		border-radius: 6px 6px 0 0;
		background: #D5DFE2;

		> .cycle-time-breakdown-title {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.002em;
			color: #0F2345;
		}

		> .cycle-time-breakdown-dates {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.002em;
			color: #142E5A;
		}
	}

	> .cycle-time-breakdown-content {
		padding: 8px 0;
		border-radius: 0 0 6px 6px;

		> .cycle-time-breakdown-list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			> .cycle-time-breakdown-list-scrollable-area {
				max-height: 300px;
				overflow-y: auto;
			}
		}
	}
}
