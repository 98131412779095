.pr-cycle-time-for-team {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.pr-cycle-time-for-team-info {
		width: 150px;
		min-width: 150px;
		max-width: 150px;
		overflow-x: hidden;
		text-overflow: ellipsis;

		.pr-cycle-time-for-team-info-name {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.002em;
			color: #0F2345;
			text-overflow: ellipsis;;
		}

		.pr-cycle-time-for-team-info-members-count {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.002em;
			color: #596566;
		}
	}

	.pr-cycle-time-for-team-breakdown {
		flex: 1;
		margin-left: 40px;

		.pr-cycle-time-for-team-breakdown-total-time {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			text-align: right;
			color: #979797;
		}

		.pr-cycle-time-for-team-breakdown-items {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			height: 8px;
			margin-top: 3px;
			border-radius: 3px;
			background-color: #EDF0F5;

			> :first-child {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}

			> :last-child {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}
	}
}
