.task-category-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 15px;
	width: 210px;
	height: 85px;
	background: #FFFFFF;
	box-shadow: 0 0 5px #CED5DF;
	border-radius: 4px;
	transition: all 165ms ease-in-out;
	cursor: pointer;

	&:hover {
		background: #E9EDF1;
	}

	&.task-category-card-active {
		background: #C1CEDE;
	}

	> .task-category-card-title {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.002em;
		color: #0F2345;
	}

	> .task-category-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
		padding-top: 11px;

		> .task-category-estimation {
			height: 27px;
			vertical-align: bottom;

			> .task-category-estimation-value {
				font-family: 'Rubik', sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 30px;
				line-height: 90%;
				letter-spacing: -0.304615px;
				text-transform: uppercase;
				color: #170987;
			}

			> .task-category-estimation-unit {
				padding-left: 4px;
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 23px;
				color: #8791A2;
			}
		}

		> .task-category-sprint-comparison {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;

			> .task-category-sprint-comparison-icon {
				display: inline-block;
				width: 7px;
				height: 3px;
				background-repeat: no-repeat;
			}

			> .task-category-sprint-comparison-value {
				padding-left: 4px;
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 15px;
				letter-spacing: -0.0843593px;
			}

			&.task-category-sprint-comparison-up {
				> .task-category-sprint-comparison-icon {
					background-image: url(../../../../components/svg-assets/arrow-up.svg);
				}

				> .task-category-sprint-comparison-value {
					color: #32B195;
				}
			}

			&.task-category-sprint-comparison-down {
				> .task-category-sprint-comparison-icon {
					background-image: url(../../../../components/svg-assets/arrow-down.svg);
				}

				> .task-category-sprint-comparison-value {
					color: #E03653;
				}
			}
		}
	}
}
