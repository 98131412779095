@import "../colors.scss";

.select-day {
	width: 100%;
	.select-day-label {
		font-size: 16px;
		letter-spacing: -0.2px;
		line-height: 21px;
		color: $font-dark-blue;
		margin-bottom: 12px;
	}

	.select-day-input-container {
		display: flex;
		flex-wrap: wrap;
		.select-day-input-options {
			height: 34px;
			width: 50px;
			border: 1px solid $color-gray;
			border-radius: 3px;
			background-color: $color-white;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin: 0 5px 5px 0px;
			&:hover {
				border: 1px solid $font-dark-blue;
			}
			&.--selected {
				border: 1px solid $font-dark-blue;
				background-color: $color-gray;
			}
			&.--error {
				border: 1px solid #e0b4b4;
				background-color: #fff6f6;
			}
		}
	}
}

.acu-select {
	width: 100%;
	position: relative;
	.acu-select-label {
		font-size: 16px;
		letter-spacing: -0.2px;
		line-height: 21px;
		color: $font-dark-blue;
		margin-bottom: 12px;
	}

	.acu-select-input {
		display: flex;
		flex-wrap: wrap;
		min-height: 34px;
		width: 100%;
		border-radius: 3px;
		background-color: $acu-dark-blue;
		padding: 2.5px 5px;
		position: relative;
		cursor: pointer;
		&:active {
			opacity: 0.9;
		}
		&.--error {
			border: 1px solid #e0b4b4;
			background-color: #fff6f6;
		}

		.acu-select-input-selected-item {
			padding-left: 10px;
			display: flex;
			align-items: center;
			background: $acu-light-purple;
			color: $color-white;
			font-size: 15px;
			letter-spacing: -0.09px;
			line-height: 19px;
			border-radius: 2px;
			margin: 2.5px;
			height: 24px;
			.acu-select-remove-option {
				width: 20px;
				margin-left: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 11px;
				}
			}
			.acu-select-remove-option::first-letter {
				text-transform: capitalize;
			}
		}

		.acu-select-input-selected-single {
			color: $font-dark-blue;
			font-size: 15px;
			letter-spacing: -0.09px;
			display: flex;
			align-items: center;
			padding: 0 8px;
		}

		.acu-select-input-placeholder {
			color: $color-text-gray;
			font-size: 15px;
			letter-spacing: -0.09px;
			display: flex;
			align-items: center;
			padding: 0 24px 0 8px;
		}

		.acu-select-arrows {
			position: absolute;
			right: 6px;
			height: 100%;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.icon {
				display: flex;
				align-items: center;
				font-size: 10px;
				font-family: "outline-icons", serif;
			}
		}
	}

	.acu-select-input-container {
		position: absolute;
		background: $color-white;
		margin-top: 6.5px;
		border-radius: 3px;
		box-shadow: 0 4px 10px 0 $acu-background-white;
		z-index: 150;
		min-width: 200px;
		max-width: 350px;

		.acu-select-input-dropdown-header {
			padding: 12px 12px 0 12px;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 12px;
		}
		.acu-select-input-search {
			border-bottom: 1px solid $acu-border-gray;
			height: 40px;
			input {
				height: 100%;
				border: none;
				&::placeholder {
					color: $acu-placeholder-gray;
					font-size: 15px;
					letter-spacing: -0.09px;
					// padding: inherit;
				}
			}
		}
		.acu-select-input-items {
			padding: 12px;
			min-height: 50px;
			max-height: 350px;
			overflow: auto;
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}
			&::-webkit-scrollbar-track {
				background: rgba(0, 0, 0, 0.1);
			}

			&::-webkit-scrollbar-thumb {
				background: $color-text-gray;
			}
			.acu-select-clear-selection {
				color: $font-dark-blue;
				font-size: 15px;
				letter-spacing: -0.09px;
				line-height: 20px;
				cursor: pointer;
				&.--disabled {
					cursor: default;
					color: $color-text-gray;
				}
			}
			.acu-select-input-item {
				min-height: 35px;
				display: flex;
				align-items: center;
				cursor: pointer;
				padding: 4px 0;
				.acu-select-input-child-item {
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					&.--disabled {
						cursor: default;
						color: $color-text-gray;
					}
					&.--selected {
						font-weight: bold;
					}
				}
			}
		}
	}

	.acu-select-input-search {
		border-bottom: 1px solid $acu-border-gray;
		height: 40px;
		input {
			height: 100%;
			border: none;
			&::placeholder {
				color: $acu-placeholder-gray;
				font-size: 15px;
				letter-spacing: -0.09px;
			}
		}
	}
	.acu-select-input-items {
		padding: 12px;
		min-height: 50px;
		max-height: 250px;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		&::-webkit-scrollbar-track {
			background: rgba(0, 0, 0, 0.1);
		}

		&::-webkit-scrollbar-thumb {
			background: $color-text-gray;
		}
		.acu-select-clear-selection {
			color: $font-dark-blue;
			font-size: 15px;
			letter-spacing: -0.09px;
			line-height: 20px;
			cursor: pointer;
			&.--disabled {
				cursor: default;
				color: $color-text-gray;
			}
		}
		.acu-select-input-item {
			min-height: 35px;
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: 4px 0;
			.acu-select-input-child-item {
				// text-transform: none;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				&.--disabled {
					cursor: default;
					color: $color-text-gray;
				}
				&.--selected {
					font-weight: bold;
				}
			}
			.acu-select-input-child-item::first-letter {
				text-transform: capitalize !important;
			}
		}
	}
}

.acu-select-hidden-input {
	visibility: hidden;
}
