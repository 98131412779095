.developer-metrics-tab-wrapper {
	margin-top: 16px;
	overflow: auto;
	width: 100%;
	flex: 1 1;
	flex-basis: 0;

	table {
		margin-bottom: 100%;
	}

	td {
		padding-bottom: 10px;
	}

	.category-title {
		font-weight: bold;
		color: #854BFE;
		text-transform: uppercase;
		margin-top: 10px;
	}
}
