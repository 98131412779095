.developer-stats-table-sort-button {
	display: block;
	background-color: transparent;
	border: none;
	height: 6px;
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 4px;
	}

	.developer-stats-table-sort-button-icon-active {
		display: none;
	}

	.developer-stats-table-sort-button-icon-inactive {
		display: block;
	}

	&:hover, &.variant-active {
		.developer-stats-table-sort-button-icon-active {
			display: block;
		}

		.developer-stats-table-sort-button-icon-inactive {
			display: none;
		}
	}
}