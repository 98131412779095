.pr-cycle-time-for-selected-teams {
	display: flex;
	flex-direction: column;
	background-color: #FFFFFF;
	padding: 16px 0 16px 16px;
	border-radius: 4px;
	height: 360px;
	max-height: 360px;

	.pr-cycle-time-for-selected-teams-header {
		padding-bottom: 8px;
		border-bottom: 1px solid #DDDDDD;

		.pr-cycle-time-for-selected-teams-header-title {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			color: #000000;
			padding: 8px 24px 8px 8px;
		}
	}

	.pr-cycle-time-for-selected-teams-content {
		padding: 12px 16px 8px 8px;
		overflow-y: auto;
		flex: 1;

		> :not(:first-child) {
			margin-top: 16px;
		}
	}
}
