@import "../../../colors";
@import "../../../vars";

.ui.segment.users-dropdown {
	$min-width: 19.933rem;
	$border-color: #CED5DC;
	z-index: $selectors-z-index;
	position: absolute;
	min-width: $min-width;
	font-size: 0.8rem;
	text-align: left;
	right: calc(100% - 2.333rem);
	padding: 0;
	border: 0.066rem solid $border-color;
	border-radius: 0.266rem;
	box-shadow: 0 0.266rem 1.066rem transparentize($acu-retro-primary, 0.75);

	.items-search-field {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 0.066rem solid $border-color;
		padding: 0.666rem 1.066rem;

		.search-icon {
			width: 1.333rem;
			height: 1.333rem;
			background: url("../../../svg-assets/search-icon.svg") center center no-repeat;
		}

		input.items-search-input {
			width: 100%;
			height: 100%;
			font-size: 0.933rem;
			line-height: 1.333rem;
			border: none;
			padding-left: 1.066rem;
		}
	}

	.action-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0.666rem 1.066rem;
		border-bottom: 0.066rem solid $border-color;
		cursor: default;

		> .action-button {
			color: $acu-light-gray-text;
			font-size: 0.933rem;
			line-height: 1.333rem;
			background: transparent;
			border: none;
			padding: 0;
			margin: 0;
			cursor: pointer;
		}

		> .action-button:not(:first-child) {
			margin-left: 0.533rem;
		}
	}

	.contributors-selector-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 40px;
		border-top: 0.066rem solid $border-color;

		.create-new-filter-button {
			color: #142E5A;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			background: transparent;
			border: none;
			padding: 12.5px;
			margin: 0;
			width: 100%;
			height: 40px;
			cursor: pointer;
			text-align: center;

			&:disabled {
				cursor: default;
				color: rgba(20, 46, 90, 0.5);
			}
		}

		> .action-button:not(:first-child) {
			margin-left: 0.533rem;
		}
	}

	ul.items {
		$padding: 0.533rem 1.066rem;
		padding: $padding;

		> li.item {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0.533rem;
			list-style-type: none;

			&.selected {
				z-index: 1;

				.image.avatar.avatar-selector-item-avatar {
					box-shadow: 0 0 0 0.133rem $color-primary, 0 0 0 0.066rem white;
				}
			}

			.ui.checkbox > input ~ label::before {
				width: 1.066rem;
				height: 1.066rem;
				border: 0.066rem solid $acu-retro-dark-gray-text;
				box-sizing: border-box;
				border-radius: 0.133rem;
			}

			.ui.checkbox.checked > input:checked {
				~ label::before {
					background: url("../../../svg-assets/bg-checkbox.svg") center center no-repeat;
					border-radius: 0.133rem;
					border: none;
				}
	
				~ label::after {
					content: "";
					background: url("../../../svg-assets/checkbox.svg") center center no-repeat;
				}
			}

			.image.avatar.avatar-selector-item-avatar {
				width: 1.6rem;
				height: 1.6rem;
				margin-left: 1.066rem;
			}

			.image.avatar.avatar-selector-item-avatar {
				box-shadow: 0 0 0 0.066rem $acu-retro-primary;
			}

			&:hover > .image.avatar.avatar-selector-item-avatar {
				box-shadow: 0 0 0 0.133rem $color-primary, 0 0 0 0.066rem white;
			}

			.avatar-selector-item-text {
				font-size: 0.933rem;
				line-height: 1.333rem;
				margin-left: 0.533rem;
			}
		}
	}
}

.ui.modal.create-contributors-filter-modal {
	width: 461px;

	.create-contributors-filter-modal-header {
		background: #F0F3F7;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 16px 24px;

		.create-contributors-filter-modal-header-side {
			display: flex;
			flex-direction: row;
			align-items: center;

			&:first-child {
				justify-content: flex-start;
			}

			&:last-child {
				justify-content: flex-end;
			}

			> :not(:first-child) {
				margin-left: 8px;
			}
		}

		.contributor-filter-modal-logo {
			width: 20px;
			height: 20px;
		}

		.create-contributors-filter-modal-title {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			display: flex;
			align-items: center;
			color: #000000;
		}

		.filter-items-badge {
			padding: 4px;
			width: 32px;
			height: 23px;
			background: #142E5A;
			border-radius: 16px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: #FFFFFF;
			text-align: center;
		}

		.create-contributors-filter-modal-close-button {
			cursor: pointer;
			background: transparent;
			border: none;
		}
	}

	.create-contributors-filter-modal-body {
		padding: 24px;

		.filter-name-label {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			color: #0F2345;
		}

		.filter-name-input {
			display: block;
			width: 100%;
			height: 40px;
			padding: 12px 16px 12px 8px;
			background: #FFFFFF;
			border: 1px solid #CED5DC;
			border-radius: 4px;
			margin-top: 8px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 47.5%;
			color: #0F2345;

			&::placeholder {
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 140%;
				color: #D7DFE2;
			}
		}
	}

	.create-contributors-filter-modal-footer {
		height: 60px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 14px 24px;
		background: #FFFFFF;
		border-top: 1px solid #CED5DC;

		.create-contributors-filter-modal-cancel-button {
			width: 96px;
			height: 32px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background: transparent;
			border: none;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			color: #5E6C84;
		}

		.create-contributors-filter-modal-save-button {
			padding: 6.5px 24px;
			width: 86px;
			height: 32px;
			background: #ED51A3;
			border: none;
			border-radius: 3px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: #FFFFFF;
			margin-left: 16px;
			cursor: pointer;

			&:disabled {
				cursor: default;
				background: #F3C4E6;
			}
		}
	}
}
