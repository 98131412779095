.trend-indicator {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	color: #979797;

	&.variant-Positive {
		color: #00AB7B;
	}

	&.variant-Negative {
		color: #E24B63;
	}

	> i.icon.trend-indicator-icon {
		display: block;
		width: 9px;
		height: 5px;
		line-height: 5px;
		margin: 0;
	}

	.trend-indicator-value {
		margin-left: 6px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
	}
}
