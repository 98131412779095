.badge {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-radius: 4px 0 4px 4px;
	cursor: default;

	&.agg-badge {
		background-color: #A172FF;

		&.badge-superstar, &.badge-topperformer {
			background-color: #A172FF;
		}

		&.badge-ontherise {
			background-color: #FFBB36;
		}

		&.badge-teamplayer {
			background-color: #72BDCD;
		}

		&.badge-needattention {
			background-color: #F77C9A;
		}

		.badge-icon {
			padding: 2px
		}

		.badge-text {
			padding: 2px 6px 1px 7px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 10px;
			line-height: 11px;
			color: #FFFFFF;
			text-align: center;
			border-left: 1px solid #FFFFFF;
		}
	}

	&.metric-badge {
		color: #FFBB36;

		&.badge-superstar, &.badge-topperformer {
			color: #A172FF;
		}

				&.badge-ontherise {
			color: #FFBB36;
		}

		&.badge-teamplayer {
			color: #72BDCD;
		}

		&.badge-needattention {
			color: #F77C9A;
		}

		.badge-icon {
			width: 19px;
			height: 19px;
			min-width: 19px;
			padding: 2px
		}

		.badge-text {
			margin-left: 6px;
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
		}
	}
}

.badge-reason {
	color: #8791A2
}