.custom-select-dropdown-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 11px 20px 8px;
	background-color: #FFFFFF;
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #0F2345;
	cursor: default;
	transition: 100ms ease-in-out;

	&:first-child {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	&:last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&:hover, &.variant-selected {
		background-color: #EDF0F5;
	}

	.custom-select-dropdown-item-text {
		&:not(:first-child) {
			margin-left: 8px;
		}
	}
}
