.filter-dropdown {
  height: 15px;

  .filter-count {
    display: inline-block;
    font-weight: bold;
  }

  .search-input.search-input.search-input {
    display: inline-block;
    width: 0;
    transition: width .1s ease-in-out;

    color: #052348;
    border-color: #052348 !important;
    border-style: solid;
    border-width: 0 0 1px 0;

    &::placeholder {
      color: #bec8d9;
    }
    &.visible {
      width: 240px;
    }
  }

  .icon {
    cursor: pointer;
  }
}

.filters-form {
  width: 340px;
  .field.field.field {
    input {
      font-size: 14px;
      font-weight: 500;
      color: #052348;
      border-color: #ced5dc;
    }
    input::placeholder {
      color:  #bec8d9;
    }
  }
}
