@import "../colors.scss";

.acu-checkbox {
    label {
        color: $font-dark-blue;
        font-size: 15px;
        letter-spacing: -0.09px;
    }
     &.checked {
        label {
            &::before {
                background: $acu-light-purple !important;
                border: none;
            }
            &::after {
                color: white !important;
            }
        }
    }
}