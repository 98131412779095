.integration-flow-step-root {
	text-align: center;

	.integration-flow-step-logo-header {
		& img {
			width: 85px;
			position: relative;
			background: #fff;
			z-index: 1;
			padding: 14px;
		}
		& hr {
			position: relative;
			top: -54px;
			border-color: #212224;
		}
	}

	.dimmer {
		position: relative;
	}

	button {
		margin: 0;
		& div.ui.small.active.inverted.inline.loader {
			margin-left: -20px;
			margin-right: 10px;
		}
	}

	.install-button {
		min-width: 250px;
	}

	form {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		& .form-group {
			min-width: 400px;
		}
		& input[type=number]::-webkit-inner-spin-button,
		& input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
		}
	}

	.integration-flow-step-text-input{
		text-align: center;
		&::placeholder {
			text-align: center;
		}
	}

	.form-control-sm {
		height: 33px;
	}

	.jira-form {
		text-align: left;
		align-items: flex-start;
		.indent-block {
			padding-left: 12px;
		}
		.form-row {
			width: 100%;
			.form-control-sm {
				width: calc(100% - 120px);
				display: inline-block;
				margin-right: 15px;
				top: 0;
				position: relative;
			}
			&.align-center {
				text-align: center;
				align-items: center;
				justify-content: center;
			}
			button {
				vertical-align: top;
			}
			textarea {
				height: 130px;
			}
		}
	}
}
