@import "../../../../components/colors.scss";

.go-retro-configurations {
	.configurations-title {
		display: flex;
		background-color: $acu-retro-hover-gray;
		margin: -1.5rem -2.5rem 0 -2.5rem;
		padding: 0.75rem 2.5rem 0.75rem 2.5rem;
	}

	.padded-icon {
		padding: 1rem 0 1rem 0;
	}

	.config-title {
		display: inline-block;
		margin: 0;
		padding: 1rem;
	}

	.buttons-row {
		padding-top: 2rem;
		.button {
			margin: 0px;
		}
	}
	.config-field {
		padding: 0.5rem 0rem;
	}
	.acu-input {
		.ui.basic.label,
		.ui.input,
		input {
			background-color: $acu-dark-blue;
			border-color: $acu-dark-blue !important;
		}
	}
	.acu-select .acu-select-input{
		background: white;
		border: 1px solid $color-gray;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.configuration-form-button {
		font-weight: 600;
	}

	.configuration-sprint-selector-wrapper {
		padding: 1rem 0 1rem 0;

		#drop-down-indicator{
			color: $acu-retro-secondary;
			font-weight: bold;

			#add-sprints{
				text-decoration: underline;
			}
		}
	}
}
