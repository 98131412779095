.pr-table-aggregated-data-header-cell {
	position: sticky;
	left: 0;
	z-index: 9999;
	padding: 6px 1px 6px 0;

	.pr-table-aggregated-data-header-cell-content {
		height: 100%;
		padding: 14px 17px;
		background-color: #DADEEC;
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		letter-spacing: 0.002em;
		color: #0F2345;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
	}
}
