.pr-cycle-time-in-phases {
	display: flex;
	flex-direction: row;

	.pr-cycle-time-in-phases-section {
		flex: 1;

		&:not(:first-child) {
			margin-left: 58px;
		}

		.pr-cycle-time-in-phases-section-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			border: 1px solid #DDDDDD;

			.pr-cycle-time-in-phases-section-header-title {
				.pr-cycle-time-in-phases-section-header-title-text {
					font-family: default-bold-font, sans-serif;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					color: #000000;
				}
			}

			.pr-cycle-time-in-phases-section-header-data {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: flex-end;

				> :not(:first-child) {
					margin-left: 30px;
				}

				.pr-cycle-time-in-phases-section-header-data-total {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: flex-end;

					.pr-cycle-time-in-phases-section-header-data-total-value {
						font-family: default-bold-font, sans-serif;
						font-style: normal;
						font-weight: 700;
						font-size: 40px;
						line-height: 30px;
						color: #000000;
					}

					.pr-cycle-time-in-phases-section-header-data-total-unit {
						font-family: default-regular-font, sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
						color: #979797;
					}
				}

				.pr-cycle-time-in-phases-section-header-data-trend {

				}
			}
		}
	}
}
