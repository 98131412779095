@import "./../colors.scss";
@import "./../style.scss";

.task-details {
	.panel-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		z-index: $menus-z-index;
	}
	.task-details-tab {
		flex-grow: 1;
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.task-details-tab::-webkit-scrollbar {
		display: none;
	}

	.task-details-tab.cards-container {
		overflow-y: auto;
		padding: 1rem;
		padding-bottom: 2.5rem;
	}
	.timeline-events-tab {
		overflow-y: auto;
		height: 100%;
	}
	.timeline-event-card {
		color: $color-darkest-blue;
		padding: 1rem 0rem;
		margin: 0rem 0.9rem;
		display: flex;
		border-bottom: 1px solid $color-light-blue-gray;
		.avatar-container {
			margin: 0px 10px;
			width: 60px;
		}
		.related-label{
			position: relative;
		}
		.content {
			display: flex;
			flex-direction: column;
			font-size: 15px;
			.small-gray-text {
				color: $color-text-gray;
				font-size: 12px;
			}
			.ui.gray.label.light-gray {
				margin-left: auto;
			}
		}
		.ui.image.avatar.big {
			margin: 0em;
			width: 40px;
			height: 40px;
			vertical-align: middle;
		}
	}
	.scroller {
		width: 100%;
		box-sizing: content-box;
	}
	.ui.table {
		border: 0;
		tr {
			th {
				background-color: #F0F3F7;
				text-transform: uppercase;
				color: $font-dark-blue;
				font-size: 14px;
				font-weight: 600;
				letter-spacing: -0.18px;
				line-height: 19px;

			}
		}
	}
	.details-header-container {
		display: flex;
	}
	.details-header-title {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: $font-dark-blue;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: -0.4px;
		line-height: 24px;

	}
	.details-header-close-icon {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		cursor: pointer;
	}
	.task-title-container {
		display: flex;
	}
	.panel-title {
		margin-top: 0;
		margin-bottom: 0;
		flex: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		&.with-icon {
			max-width: 90%;
		}
	}
	.task-title-settings-icon {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		cursor: pointer;
	}
	.task-property-header {
		color:  #B1BCC7;
	}
	.task-property-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.task-property-text {
		margin-left: 4px;
	}
	.assignee-name {
		text-transform: capitalize;
	}
	.vertical-spacer-x {
		margin-top: 4px;
	}
	.vertical-spacer-2x {
		margin-top: 8px;
	}
	.vertical-spacer-4x {
		margin-top: 16px;
	}
	.ui.gray.label.light-gray {
		background-color: $color-bg-blue-gray;
		font-size: 11px;
		white-space: nowrap;
		height: 2em;
		.link {
			text-decoration: none;
			border-bottom: 1px solid $color-primary;
			color: $color-darkest-blue;
			opacity: 1;
		}
	}

	.ui.label.insight-resolved{
		float: right;
		background-color: $color-primary;
		color: white;
		font-size: 11px;
	}

	.noTabContent {
		margin: auto;
		p {
			margin: 0px auto;
			font-size: 1.25em;
			color: $color-text-gray;
		}
		img {
			margin: 0px auto;
		}
	}
	.acu-link {
		border-color: $acu-light-purple;
	}

}
