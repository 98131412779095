
.react-bootstrap-table {
	.table {
		color: #0F2345;

		thead {
			background-color: #E9EEF4;
			border: 0;
			font-size: 1.1em;
			letter-spacing: 0.03em;
		}

		td {
			border-top: 1px solid #AEB8C5;
			width: 1%;
			vertical-align: middle;
		}

		tr {
			.action-buttons {
				visibility: hidden;
			}
			&:hover {
				background: #EBEEF2;
				.action-buttons {
					visibility: initial;
				}
			}
		}

		@media only screen and (min-width: 1024px) {
			td, th {
				padding: 14px 20px;
			}
		}
	}
}

.action-buttons {
	z-index: 9999;
	& > * i {
		font-size: 20px;
	}
	background-color: inherit;
}

.img-cell {
	max-width: 40px;
	max-height: 40px;
}
