.developer-stats-team-trends-card {
	background-color: #FFFFFF;
	border-radius: 4px;
	flex: 1;

	.developer-stats-team-trends-card-content {
		padding: 12px 5.5px 14px 20px;

		.developer-stats-team-trends-card-content-title {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #000000;
		}
	}
}
