@import "./../../../../components/colors.scss";

.tasks-tab {
	.controls.controls {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.filter-spacer-right {
		padding-right: 0.75rem;
	}
	.filter-spacer-left {
		padding-left: 0.75rem;
	}
	.filter-separator {
		border-left: 1px solid rgb(206, 213, 220);
		border-left: 1px solid rgba(206, 213, 220, 0.5);
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
		cursor: pointer;
	}
	.filter-object {
		padding-left: 0.75rem;
		display: table-cell;
		vertical-align: middle;
		cursor: pointer;
	}
	.ui.table {
		border: none;
		th {
			border: none;
			height: 20px;
			background: #f1f4f8;
			color: #0f2345;
			font-weight: bold;
		}
		td {
			.link {
				color: inherit;
			}
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.task-highlight-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
	}
	.task-highlight-text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.rows-placeholder {
		min-height: calc(15 * 30px);
	}
	.see-all-link {
		color: $acu-light-purple;
		margin: 0 0 0 10px;
		letter-spacing: -0.2px;
	}
	.right-side-controls {
		font-size: 1rem;
		.control-width {
			min-width: 280px;
			padding-left: 1rem;
		}
	}

	.side-margin {
		margin: 0px 3px;
	}
	.task-title:after {
		content: "\00a0\00a0\00a0\00a0\00a0\00a0 ";
	}
}

.change-table-column-width {
	th.maker-time {
		width: 12.5% !important;
	}
	th.status {
		width: 18.75% !important;
	}
	th.issues-table-title {
		width: 31.25% !important;
	}
}
