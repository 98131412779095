@import "../colors.scss";
@import "../vars.scss";

$color-background: #F0F3F7;
$button-border-radius: 1px;
$border-color: #CED5DC;

.filters-navbar-wrapper {
  min-width: 250px;
}

.acu-filters-selector {
  &.--disabled {
    pointer-events: none;
    .acu-filters-selector-value {
      color: $acu-placeholder-gray !important;
    }
  }
  .acu-filters-select-input {
    min-width: 250px;
    .acu-filters-input-value {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .acu-filters-arrow {
      width: 20px;
      font-family: 'outline-icons', serif;
      font-size: 11px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }
  .acu-filters-selector-container {
    position: absolute;
    background: $color-white;
    margin-top: 6.5px;
    border-radius: 3px;
    box-shadow: 0 4px 10px 0 $acu-background-white;
	z-index: 10000;
    min-width: 200px;
    min-height: 100px;
    display: flex;
    padding: 16px;
    right: 0;

    &.time-frames-selector-container {
      width: 100%;
      padding: 0;

      > .time-frames-selector {
        width: 100%;
      }
    }

    .acu-filters-selector-section {
      padding: 8px;

      &.time-frames-selector {
        padding: 0;

        > .items-search-field {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 0.066rem solid $border-color;

          .search-icon {
            width: 1.333rem;
            height: 1.333rem;
            margin-left: 1.066rem;
            background: url("../svg-assets/search-icon.svg") center center no-repeat;
          }

          input.items-search-input {
            width: 100%;
            height: 100%;
            font-size: 0.933rem;
            line-height: 1.333rem;
            border: none;
            padding: 0.666rem 1.066rem;
          }
        }

        > .time-frames-selector-content {
          padding: 24px;
        }

        &.time-frames-selector-with-search-bar {
          > .time-frames-selector-content {
            padding: 0;
          }
        }
      }

      &.sprint-selector {
        width: 230px;
      }

      .acu-filters-selector-title {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.09;
        font-weight: 600;
        color: $font-dark-blue;
        margin-bottom: 16px;
        padding-left: 24px;
      }

      .acu-filters-selector-options {
          max-height: 240px;
          overflow: auto;
          white-space: nowrap;
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          &::-webkit-scrollbar-track {
            background: $color-white;
          }

          &::-webkit-scrollbar-thumb {
            background: $acu-border-gray;
          }

        .acu-filters-selector-options-item {
          height: 30px;
          background: blue;
          background: $color-white;
          color: $font-dark-blue;
          padding: 6px 16px 6px 28px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          margin-bottom: 4px;

          &:hover {
            background: $acu-background-gray;
            color: $acu-light-purple;
          }
          &.--selected {
            color: $acu-light-purple;
          }
          &.--disabled {
            color: $acu-light-gray-text;
            cursor: default;
            &:hover {
              background: $color-white;
              color: $acu-light-gray-text;
            }
          }

          .acu-filters-selector-options-checkmark {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 10px;
            font-size: 11px;
          }
        }
      }
    }
  }

  .datepicker-wrapper {

    .react-datepicker__navigation {
      top: -25px;
    }
    .datepicker-custom-year {
      color: #0F2345;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: -0.09px;
      line-height: 20px;
      text-align: center;
      width: 100%;
    }

    .react-datepicker {
      border: none;
      display: flex;
      min-width: 500px;
      justify-content: space-between;
      padding: 0 8px;
      .react-datepicker__header {
        border: none;
      }
      .datepicker-custom-header {
        height: 24px;
        background: $color-background;
        margin-bottom: 10px;
        font-weight: normal;
        line-height: 24px;
        padding: 0 !important;

        .custom-header-navigation-section {
        }
      }
      .react-datepicker__current-month {
        height: 24px;
        background: $color-background;
        margin-bottom: 10px;
        font-weight: normal;
        line-height: 24px;
      }

      div[class*="react-datepicker__"] {
        color: $font-dark-blue;
      }

	.react-datepicker__day--keyboard-selected {
		background-color: transparent !important;
	}

      .react-datepicker__day--in-selecting-range {
        background: $color-lighter-primary !important;
      }

      .react-datepicker__day--in-range {
        background: $acu-dark-blue !important;
        color: $font-dark-blue !important;
      }

      .react-datepicker__day--range-start {
        background: $color-primary !important;
        color: $color-white !important;
      }

      .react-datepicker__day--range-end {
        background: $color-primary !important;
        color: $color-white !important;
      }
    }
    color: $font-dark-blue;
    .basic.label,
    .button {
      line-height: 1rem;
      background: $color-light-gray;
      border: none;
      border-radius: $button-border-radius !important;
    }
    .basic.label:active,
    .button:active,
    .basic.label:focus,
    .button:focus,
    .basic.label:hover,
    .button:hover {
      background: $color-gray;
    }

    .basic.label {
      margin: auto;
      width: 100%;
      text-align: center;
    }
    .side-button {
      color: inherit;
      padding: 0.5rem;
      .icon {
        margin: 0px !important;
        vertical-align: top;
      }
      border-radius: $button-border-radius;
    }
    .side-button:first-child {
      margin-right: 0.25rem;
      border-radius: $button-border-radius !important;
    }
    .side-button:last-child {
      margin-left: 0.25rem;
      border-radius: $button-border-radius !important;
    }
    .center-button {
      min-width: 200px;
      background: $color-light-gray;
      .button {
        color: $font-dark-blue;
        vertical-align: baseline;
        line-height: 1rem;
      }
    }
  }

  .ui.popup {
    background: $color-light-gray;
    border-radius: $button-border-radius;
    .react-datepicker {
      border: 1px solid $color-gray;
    }
  }
  .ui.popup::before {
    background: $color-light-gray !important;
  }

  .react-datepicker__header {
    background-color: $color-white !important;
  }

  .react-datepicker__day--selected {
    background-color: $color-gray !important;
  }

  .datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range{
    background-color: #456B99 !important;
    color: white !important;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day:disabled {
    color: $color-light-gray !important;
  }

}
