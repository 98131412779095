@import "../colors.scss";

.pie-chart {
	.pie {
		position: relative;
		border-radius: 50%;
		background: transparent;
		transform: rotate(-180deg);
		animation: dash 5s linear forwards;
	}

	circle {
		fill: none;
		stroke-width: 32;
	}

	.pie-indicator {
		stroke-width: 1.25px;
		stroke: white;
	}

	.pie-indicator {
		fill: $color-light-blue;
		&.gray {
			fill: $color-gray;
		}
		&.blue {
			fill: $color-light-blue;
		}
		&.green {
			fill: $acu-green-success;
		}
		&.orange {
			fill: $color-orange;
		}
	}

	circle.pie-fill {
		stroke: $color-light-blue;
		&.blue {
			stroke: $color-light-blue;
		}
		&.green {
			stroke: $acu-green-success;
		}
		&.orange {
			stroke: $color-orange;
		}
	}

	circle.pie-background {
		stroke: $color-light-blue-background;
		&.gray {
			stroke: $color-gray;
		}
		&.blue {
			stroke: $color-light-blue-background;
		}
		&.green {
			stroke: $acu-green-background;
		}
		&.orange {
			stroke: $color-orange-background;
		}
	}

	.pie-indicator-h {
		fill: white;
	}

	.animate {
		animation: dash 1s linear forwards;
	}

	@keyframes dash {
		from {
			stroke-dasharray: 0 100;
		}
	}
}

tr:hover,
.pie-chart:hover {
	.pie-indicator-h {
		fill: $color-bg-light-gray !important;
		z-index: 1;
	}
}

.bar-chart {
	display: inline-flex;
	margin: 0px 10px;
	.no-value {
		padding-left: 4px;
		position: relative;
		color: $color-text-gray;
		font-weight: 800;
	}
	.bar.animate {
		animation: bar 1s ease-in forwards;
	}
	.ghost-bar {
		background-color: $color-gray;
	}
}

.stacked-bar-chart {
	display: inline-flex;
	padding-top: 20px;
	margin: 0px 10px;
	.hover-bar {
		visibility: hidden;
		font-size: 12px;
	}
	.visible {
		visibility: visible;
	}
}

.empty-bar {
	margin: 0px 10px;
}

@keyframes bar {
	from {
		width: 0;
	}
}
