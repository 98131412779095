.developer-stats-cards {
	display: flex;
	flex-direction: row;

	.developer-stats-cards-grid {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		width: 705px;
		min-width: 705px;
		margin-top: -7px;
		margin-left: 7px;

		> * {
			margin-top: 7px;
			margin-left: 7px;
		}
	}
}
