@import "../colors.scss";

.acu-input {
    width: 100%;
    position: relative;
    .acu-input-label {
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 21px;
        color: $font-dark-blue;
        margin-bottom: 12px;
    }

    .acu-text-input {
        min-height: 34px;
        width: 100%;
        border-radius: 3px;
        position: relative;

        .label {
            padding: 0!important;
            line-height: unset !important;
            width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.--error{
            &.flatpickr-input,
            input {
                border: 1px solid #E0B4B4 !important;
                background-color: #FFF6F6 !important;
            }
        }

        &.flatpickr-input,
        input {
            width: auto !important;
            padding: 2.5px 15px !important;
            &::placeholder {
                color: $acu-placeholder-gray !important;
                font-size: 15px !important;
                letter-spacing: -0.09px !important;
            }
        }
    }
}

.flatpickr-calendar {
	width: 13rem !important;
}
