.developer-info-cell-content {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-width: 0;
	max-width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0 16px;
	cursor: pointer;

	.developer-info-cell-content-summary {
		min-width: 0;
		margin-left: 20px;

		.developer-info-cell-content-summary-name {
			font-family: default-bold-font, sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.002em;
			color: #0F2345;
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.developer-info-cell-content-summary-role {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.002em;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #596566;
		}
	}

	.developer-info-cell-content-badges {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		position: absolute;
		width: 100px;
		top: 0;
		right: 0;

		> :not(:first-child) {
			margin-top: 2px;
		}
	}
}
