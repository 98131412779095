.trend-card-v2 {
	width: 134px;
	min-width: 134px;
	max-width: 134px;
	height: 254px;
	min-height: 254px;
	max-height: 254px;
	padding: 18px 17px 18px 11px;
	overflow: hidden;
	background: #FFFFFF;
	box-shadow: 0px -1px 6px #CED5DF;
	border-radius: 4px;

	.trend-card-title {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		white-space: nowrap;
		color: #2278F3;
		margin: 0;
	}

	.trend-card-subtitle {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #979797;
		margin: 6px 0 0;
	}

	.trend-card-total-container {
		margin-top: 23px;
	}

	.trend-card-indicator-container {
		margin-top: 8px;
		margin-left: 3px;
	}

	.trend-card-chart-container {
		margin-top: 30px;
	}
}
