.pr-cycle-time-for-team-breakdown-item {
	height: 8px;
}

.ui.popup.cycle-time-status-tooltip {
	padding: 4px;

	&.top {
		&::before {
			box-shadow: 0 10px 10px 0 rgba(34, 36, 38, 0.12);
		}
	}

	&.bottom {
		box-shadow: 0 -2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);

		&::before {
			box-shadow: 0 -10px 10px 0 rgba(34, 36, 38, 0.12) !important;
		}
	}
}

.cycle-time-status {
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;
	color: #32B195;

	&.variant-WIP {
		&.variant-bg { background-color: #1565CD; }
		&.variant-color { color: #2278F3; }
	}

	&.variant-AwaitingReview {
		&.variant-bg { background-color: #32B195; }
		&.variant-color { color: #32B195; }
	}

	&.variant-InReview {
		&.variant-bg { background-color: #925EFF; }
		&.variant-color { color: #925EFF; }
	}

	&.variant-PendingMerge {
		&.variant-bg { background-color: #FFBB36; }
		&.variant-color { color: #FFBB36; }
	}

	.cycle-time-status-data {
		.cycle-time-status-data-value {
			font-family: default-bold-font, sans-serif;
			font-weight: 600;
		}
	}
}
