.team-comparison-table {
	position: relative;
	padding-top: 15px;

	.team-comparison-table-add-column-button {
		cursor: pointer;
		background-color: #A172FF;
		border: none;
		position: absolute;
		top: 0px;
		right: -15px;
		width: 30px;
		height: 30px;
		min-width: 30px;
		min-height: 30px;
		max-width: 30px;
		max-height: 30px;
		border-radius: 50%;
		z-index: 10001;
	}
}