.pr-cycle-time-summary {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	padding-bottom: 27px;
	border-bottom: 1px solid #DDDDDD;

	.pr-cycle-time-summary-title {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 48px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
	}

	.pr-cycle-time-summary-data {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;

		> :not(:first-child) {
			margin-left: 30px;
		}
	}
}
