.data-contributor-profile-picture {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;
	min-width: 34px;
	min-height: 34px;
	border-radius: 50%;
	background-color: #ED51A3;
	font-family: default-regular-font, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	text-transform: uppercase;
	color: #FFFFFF;
	cursor: default;
	user-select: none;
	position: relative;
}
