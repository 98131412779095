.developer-detailed-profile {
	padding: 56px 26px 0;

	.developer-detailed-profile-header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.developer-detailed-profile-info {
			margin-left: 19px;

			.developer-detailed-profile-info-name {
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.002em;
				color: #0F2345;
			}

			.developer-detailed-profile-info-role {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.002em;
				color: #596566;
			}
			.developer-detailed-profile-info-badges {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				margin-top: 13px;

				> :not(:first-child) {
					margin-left: 4px;
				}
			}
		}
	}

	.developer-detailed-profile-details-list {
		margin-top: 40px;

		.developer-detailed-profile-details-list-item {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			&:not(:first-child) {
				margin-top: 23px;
			}

			.developer-detailed-profile-details-list-item-label {
				min-width: 90px;
				max-width: 90px;
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				display: flex;
				align-items: center;
				color: #0F2345;
			}

			.developer-detailed-profile-details-list-item-content {
				margin-left: 72px;
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: #0F2345;
			}
		}
	}

	.developer-detailed-profile-team-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: -8px;
		margin-top: -5px;

		.developer-detailed-profile-team-list-item {
			padding: 2px 11px 2px 14px;
			border: 1px solid #DADEEC;
			border-radius: 3px;
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			text-align: center;
			color: #596566;
			margin-left: 8px;
			margin-top: 5px;
		}
	}
}
