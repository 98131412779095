@import "../../components/colors.scss";
@import "../../components/style.scss";

$min-card-width: 150px;

.org-metrics-container {
	height: 100%;
	padding-top: 6px;
	padding-left: 21px;
	padding-right: 107px;
	color: $font-dark-blue;

	.org-metrics-title {
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
		padding: 0;
		margin-bottom: 0;
		display: flex;
	}
	.header-container {
		margin-top: 30px;
		margin-bottom: 16px;
	}
	.cards-row {
		min-height: 220px;
	}

	.sticky-cards-row {
		position: sticky !important;
		top: 0;
		z-index: 1;
		background: #f7f7f7;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.stack-bar-centered {
		padding: 1.5rem 0px 0px 1.5rem !important;
	}

	.ui.segment.loading {
		margin: auto;
	}
	.flex-gap {
		display: inline-flex;
		flex-wrap: wrap;
	}

	.flex-gap>div {
		margin: 6px;
	}

	.org-metric-chart {
		margin-top: 23px;
		margin-bottom: 10px;
	}
	.org-metric-chart-card {
		border-radius: 4px;
		background-color: white;
		border: transparent;
	}
	.left-aligned {
		text-align: left;
	}

	.card-bottom-space {
		margin-bottom: 6px;
	}
	.card-bottom-double-space {
		margin-bottom: 12px;
	}

	.cycle-time-cell {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: normal;
		align-items: normal;
		align-content: normal;
	}
	.cycle-time-top {
		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		margin-bottom: 20px;
	}

	.trend-grid {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		flex-wrap: wrap;
		align-items: stretch;
		flex: 1 0 auto;
 		gap: 2px;
  		height: 100%;
	}
}

.graphs-suggestion {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 63px;

	.graphs-suggestion-text {
		font-family: default-regular-font, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		color: #001A72;
	}

	.graphs-suggestion-button {
		width: 231px;
		padding: 14px 0;
		margin-top: 22px;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: #FFFFFF;
		background-color: #925EFF;
		border-radius: 5px;
		border: none;
		cursor: pointer;
	}

	.graphs-suggestion-button:hover {
		background-color: #8354e5;
	}
}

.org-metrics-cards {
	margin-top: 16px;

	> :not(:first-child) {
		margin-top: 9px;
	}
}

.pr-cycle-time-chart {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;

	> * {
		&:first-child {
			min-width: 587px;
		}

		&:last-child {
			flex: 1;
			margin-left: 58px;
		}
	}

	.pr-cycle-time-chart-title-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 48px;
		margin-bottom: 17.5px;
	}

	.pr-cycle-time-chart-title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #000000;
		margin: 0 0 0 37px;
		.right {
			display: flex;
			/*align-self:flex-end;*/
			margin-left: auto;
			/* added */
		}

		.pr-cycle-time-see-breakdown {
			font-family: default-regular-font, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			text-decoration-line: underline;
			color: #925EFF;
		}
	}
}

.mttr-priority-left {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	padding-top: 10px;

	* {
		flex: 1;
	}
}

.mttr-priority-right {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;

	.mttr-priority-chart-title {
		width: 161px;
		text-align: center;
		font-family: default-bold-font, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		margin: 0 0 0 21px;
	}

	.mttr-priority-chart-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.mttr-priority-chart-pie {
			width: 198px;
			height: 198px;
			padding-top: 12px;
		}

		.mttr-priority-chart-legend {
			margin-left: 48px;
		}
	}
}

.issue-cycle-time-by-type-chart {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	> * {
		&:first-child {
			flex: 5;
		}

		&:last-child {
			flex: 4;
			margin-left: 55px;
		}
	}

	.issue-cycle-time-by-type-chart-header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;

		.issue-cycle-time-by-type-chart-title-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex: 1;

			.issue-cycle-time-by-type-chart-title {
				max-width: 107px;
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				text-align: center;
				color: #000000;
			}
		}

		.issue-cycle-time-by-type-chart-header-filler {
			flex: 1;
			margin-left: 48px;
			padding-right: 16px;
		}
	}

	.issue-cycle-time-by-type-chart-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;

		.issue-cycle-time-by-type-chart-pie {
			width: 198px;
			height: 198px;
			padding-top: 12px;
			flex: 1;
		}

		.issue-cycle-time-by-type-chart-legend {
			flex: 1;
			margin-left: 48px;
			padding-right: 16px;
		}
	}
}

.org-metrics-velocity-container {
	padding-top: 24px;
	padding-right: 16px;
}
