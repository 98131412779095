@import "../colors.scss";
@import "../style.scss";

$sidebar-width: 260px;

#filter-drawer {
	min-width: 3.5rem;
	text-align: left;
	display: flex;
	align-items: center;

	.filter-drawer.menu {
		z-index: $menus-z-index;
	}

	.sidebar.filter-drawer {
		background-color: white;
		box-shadow: 0 0 0 0px $color-gray, 0 0px 0px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
	}

	.filters-select {
		width: 200px;
		height: 300px;
		position: absolute;
		opacity: 0;
		z-index: $highest-z-index - 1;
		background-color: white;
		transition: opacity 0.5s;
		transition: top none;
		left: calc(260px + 1rem);
		visibility: hidden;
	}

	.filters-select.active {
		visibility:visible;
		box-shadow: 0 0 0 0px $color-gray, 0 0px 0px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
		opacity: 1;
		transition: top 0s 0s;
	}

	.drawer-content {
		overflow: hidden !important;
		box-shadow: inset 7px 0px 7px -0px $color-bg-blue-gray;
		height: 100%;

		.underline {
			border-bottom: 1px solid $color-gray;
			width: $sidebar-width;
		}

		.overline {
			border-top: 1px solid $color-gray;
			width: $sidebar-width;
		}

		.filters-title {
			padding: 2rem 1rem;
			font-size: 18px;
			margin: 0px;
		}
	}

	.drawer-header {
		font-size: 16px;
		padding: 1rem;
		border-bottom: 1px solid $color-gray;
	}

	.checkbox.field.acu-checkbox {
		text-align: left;
		white-space: nowrap;
	}

	.button-row {
		padding: 1rem;
		justify-content: flex-end;
	}

	.filters-icon-container {
		height: 25px;
		width: 25px;
		position: relative;
		color: $color-white;
		cursor: pointer;
		&.--selected {
			color: #052348;
		}
		&:hover {
			color: $color-gray;
		}
		&:active {
			color: $acu-light-gray-text;
		}
	}
}

textarea:focus,
input:focus {
	outline: none !important;
}

.flex-centered {
	display: flex;
	align-self: center;
	align-content: center;
}

.options {
	div[class*="-control"]:hover {
		cursor: text;
	}
}

#filters-view-options {
	div[class*="-ValueContainer"] {
		padding: 0.2em 0px !important;

	}

	div[class*="-placeholder"] {
		font-size: 14px;
		color: $color-text-gray;
		text-align: center;
		width: 98%;
		margin: 0px;
	}
}

.light-scroll::-webkit-scrollbar {
	width: 14px;
}

.light-scroll::-webkit-scrollbar-thumb {
	background-clip: content-box;
	border: 4px solid transparent;
	border-radius: 7px;
	box-shadow: inset 0 0 0 10px;
	color: $color-gray;
}

.light-scroll::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}

.light-scroll::-webkit-scrollbar-corner {
	background-color: transparent;
}

.light-scroll {
	overflow: auto;
	overflow-y: auto;
	transition: color 0.3s;
	max-height: 50vh;
	overflow-y: scroll;
}

.filters-select {
	div[class*="-multiValue"] {
		visibility: hidden !important;
		display: none !important;
	}
}
