@import "../colors.scss";

.primary-button {
    border-radius: 3px !important;
}

.minimal-button {
	background: transparent;
	border-radius: 3px !important;
	border: none;
	color: $font-dark-blue;
	font-size: 16px;
	letter-spacing: -0.2px;
	line-height: 21px;
	height: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	.icon {
		font-size: large;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}