.edit-contributor-labels-button {
	display: flex;
  	flex-shrink:0;
	width: 22px;
	height: 24px;
	margin-left: 4px;
	background: #F9FBFF;
	border: 1px solid #DADEEC;
	border-radius: 3px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

.edit-contributor-labels-button:hover {
	background: #e0e1e5;
}
