@import "../../components/colors.scss";

.show-background-image {
	min-height: 50rem;
	width: 100%;
	background-position: 95% 30%;
	background-repeat: no-repeat;
}

#empty-state-page {
	margin-top: 6rem;
	margin-left: 9rem !important;
	width: 100%;

	.acumen-logo {
		height: 18px;
		width: 123px;
	}

	.empty-state-page-title {
		margin-top: 1rem;
		font-size: 3.2rem;
		letter-spacing: -0.29px;
		line-height: 52px;
	}

	.empty-state-page-line {
		margin-top: 1rem;
		color: #345979;
		font-size: 1.3rem;
		letter-spacing: -0.11px;
		line-height: 20px;
	}

	.cant-integrate {
		margin-top: 2rem;
		color: currentColor;
	}

	.underline-integrations {
		box-sizing: border-box;
		height: 1px;
		width: 100%;
		border: 1px solid $acu-border-gray;
	}

	.extra-margin-title {
		margin-top: 8rem !important;
		margin-bottom: 1rem;
	}

	.all-integrations {
		width: fit-content;
		width: -moz-fit-content;
	}

	.ui.card {
		width: 13rem;
		border-radius: 0.2rem;
		box-shadow: 0 2px 5px 0 $acu-light-shadow;
		cursor: pointer;

		.disabled {
			opacity: 0.6;
			cursor: default;
		}

		.integration-button {
			display: flex;
			justify-content: space-between;

			> img {
				width: 3.5rem;
				padding: 1rem 0 1rem 1rem;
			}

			> span {
				align-self: center;
				color: $font-dark-blue;
				font-size: 1.3rem;
				letter-spacing: -0.14px;
				line-height: 26px;
				text-align: center;
				width: 100%;
			}
		}
	}
}
