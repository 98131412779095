.pr-cycle-time {
	display: flex;
	flex-direction: column;
	background-color: #FFFFFF;
	padding: 16px;
	border-radius: 4px;
	height: 360px;
	max-height: 360px;

	.pr-cycle-time-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 8px;
		border-bottom: 1px solid #DDDDDD;

		.pr-cycle-time-header-title-container {
			padding: 0 8px 3px;

			.pr-cycle-time-header-title {
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				color: #000000;
			}

			.pr-cycle-time-header-trend-container {
				margin-top: 3px;
			}
		}

		.pr-cycle-time-header-sum {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-start;

			.pr-cycle-time-header-sum-value {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				height: 32px;
				font-family: default-bold-font, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 40px;
				line-height: 27px;
				color: #000000;
			}

			.pr-cycle-time-header-sum-unit {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				height: 34px;
				margin-left: 6px;
				font-family: default-regular-font, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: #979797;
			}
		}
	}

	.developer-stats-cards-grid {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		gap: 11px;
		padding: 12px 0;

		.developer-stats-cards-grid-item {
			height: 131px;
			width: calc(50% - 6px);
		}
	}
}
