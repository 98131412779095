@import "../../components/colors.scss";

.show-background-image {
	min-height: 50rem;
	width: 100%;
	background-position: 95% 30%;
	background-repeat: no-repeat;
}

#empty-state-page {
	margin-top: 6rem;
	margin-left: 9rem !important;
	width: 100%;

	.acumen-logo {
		height: 18px;
		width: 123px;
	}

	.empty-state-page-title {
		margin-top: 1rem;
		font-size: 3.2rem;
		letter-spacing: -0.29px;
		line-height: 52px;
	}

	.empty-state-page-line {
		margin-top: 1rem;
		color: #345979;
		font-size: 1.3rem;
		letter-spacing: -0.11px;
		line-height: 20px;
	}

	.integration-button {
		margin-top: 2rem;
	}
	.cant-integrate {
		margin-top: 2rem;
		color: currentColor;
	}
}
